import React, {useEffect, useState} from "react";
import {Grid, Paper} from "@mui/material";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.es';
import moment from "moment";
import {apiList, apiCreate, apiDelete} from "../../../../services/Admin/holiday";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import LoadingBar from "../../../../components/LoadingBar";

export default function Management() {

    const [loading, setLoading] = useState(true)
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setSuccess] = useState([])
    const [value, setValue] = useState([])
    const [daySelect, setDaySelect] = useState(null)

    const handleDayClick = (date) => {
        setDaySelect(date.date)
    }

    useEffect(() => {
        if (daySelect) {
            let dateExist = value.filter(function (item) {
                return moment(item.startDate).format("YYYY-MM-DD") === moment(daySelect).format("YYYY-MM-DD");
            }).length > 0 ? true : false;

            if (!dateExist) {
                createDate(daySelect)
            } else {
                deleteDate(daySelect)
            }
        }
    }, [daySelect])

    useEffect(() => {
        getDate()
    }, [])

    const createDate = (dateTime) => {
        if (dateTime) {
            dateTime = moment(dateTime).format("YYYY-MM-DD")
            dateTime = moment(dateTime).format("X")
            setLoading(true)

            apiCreate({
                date: dateTime,
            }).then(resp => {
                setSuccess(resp.data.msg)
                getDate()
            }).catch(err => {
                setMsgError(err.response?.data?.msg)
                setLoading(false)
            })
        }
    }

    const deleteDate = (dateTime) => {
        if (dateTime) {
            dateTime = moment(dateTime).format("YYYY-MM-DD")
            dateTime = moment(dateTime).format("X")
            setLoading(true)

            apiDelete({
                date: dateTime,
            }).then(resp => {
                setSuccess(resp.data.msg)
                getDate()
            }).catch(err => {
                setMsgError(err.response?.data?.msg)
                setLoading(false)
            })
        }
    }

    const getDate = () => {
        setLoading(true)

        apiList({}).then(resp => {
            let temp = resp.data.data?.map(doc => {
                return {
                    id: doc.id,
                    startDate: new Date(moment.unix(doc.date).format("YYYY-MM-DD")),
                    endDate: new Date(moment.unix(doc.date).format("YYYY-MM-DD")),
                    color: '#ff0000'
                }
            })

            setValue(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
        })
    }

    return (
        <>
            <HeaderPage title="Festivos" />

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={1}>

                <Grid item xs={12}>

                    <Paper sx={{display: 'flex', flexDirection: 'column'}} className={"calendar-picker"}>
                        <LoadingBar loading={loading}/>

                        <Grid container spacing={3} sx={{p: 2}}>

                            <Grid item xs={12} md={12} lg={12}>
                                <Calendar
                                    language="es"
                                    onDayClick={handleDayClick}
                                    dataSource={value}
                                />
                            </Grid>

                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
