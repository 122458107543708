import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {apiCreate, apiGetRoute, apiUpdate} from "../../../../services/Admin/route";
import InputText from "../../../../components/Inputs/InputText";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Grid, Paper, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import LoadingBar from "../../../../components/LoadingBar";


export default function Edit() {
    const {id} = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const params = new URLSearchParams(window.location.search)

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)

    const [checkedAccess, setCheckedAccess] = useState(true)

    const myRef = useRef(null)

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    // Llamamos a la API para obtener los datos de la ruta
    useEffect(() => {
        if (id) {
            void getRoute()
        } else {
            setLoadingGetInfo(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

   
    const getRoute = () => {
        setMsgError([])

        apiGetRoute(id).then(resp => {
            
            if(resp.data.data.color) {resp.data.data.color=`#${resp.data.data.color}`}
            
            setState(initialValues(resp.data?.data))
            
            setLoadingGetInfo(false)
            if (resp.data?.data?.isActive == 1)  {
                setCheckedAccess(true)
            } else {
                setCheckedAccess(false)
            }
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
        if (event.target.checked) {
            setCheckedAccess(true)
        } else {
            setCheckedAccess(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        if (id) {
            void updateRoute()
        } else {
            void createRoute()
        }
    }

    const updateRoute = () => {
        apiUpdate(state).then(resp => {
            // setMsgSuccess(resp.data?.msg)
            // setLoading(false)
            // myRef.current.scrollIntoView()
            history.push(`${RouteAdmin.ROUTE_MANAGEMENT.path}/?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createRoute = () => {
        apiCreate(state).then(resp => { 
            //history.push(`${RouteAdmin.ROUTE_EDIT.path}/${resp.data?.id}?msg=${resp.data?.msg}`)
            history.push(`${RouteAdmin.ROUTE_MANAGEMENT.path}/?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }


    return (
        <>
            <div ref={myRef}></div>
            <HeaderPage title="Configurar Línea" urlBack={RouteAdmin.ROUTE_MANAGEMENT.path}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <LoadingBar loading={loadingGetInfo} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">{t('user.settings')}</h4>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12}>
                                        <InputText
                                            label="Código"
                                            required
                                            name="code"
                                            value={state?.code}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label="Nombre"
                                            required
                                            name="name"
                                            value={state?.name}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label="Color"
                                        className="form-control"
                                        name="color"
                                        type="color"
                                        value={state?.color}
                                        onChange={handleChange}
                                        fullWidth
                                        size="small"
                                    />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>

                                    <InputText
                                            label="URL PDF"
                                            name="pdf"
                                            value={state?.pdf}
                                            onChange={handleChange}
                                        />

                                    </Grid>

                                    <Grid item xs={12}>
                                    <TextField
                                        label="Descripción"
                                        name="description"
                                        value={state?.description}
                                        onChange={handleChange}
                                        multiline
                                        maxRows={4}
                                        fullWidth
                                        />
                                   

                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputSwitch
                                            label="Activo"
                                            name="isActive"
                                            value={state?.isActive}
                                            checked={checkedAccess}
                                            onChange={handleChangeChecked}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? 'Modificar' : 'Crear'}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
   
    return {
        id: props?.id || "",
        name: props?.name || "",
        code: props?.code || "",
        color:  props?.color || "",
        isActive: props?.isActive || true,
        pdf: props?.pdf || "",
        description: props?.description || ""
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.name) {
        error.push("El nombre es obligatorio")
    }
    if (!schema.code) {
        error.push("El código es obligatorio")
    }
    // if (!schema.password) { error.push("La contraseña es obligatoria") }

    return error
}
