import Login from "../pages/Auth/Login"
import Register from "../pages/Auth/Register"
import ForgottenPassword from "../pages/Auth/ForgottenPassword"
import RecoverPassword from "../pages/Auth/RecoverPassword"

const baseTitle = "BusGarraf - "

export const RouteAuth = {
    LOGIN: {
        path: "/login",
        page: Login,
        title: `${baseTitle}Login`,
        roles: ["ROLE_PUBLIC"]
    },
    SIGNUP: {
        path: "/register",
        page: Register,
        title: `${baseTitle}Register`,
        roles: ["ROLE_PUBLIC"]
    },
    RECOVER_PASSWORD: {
        path: "/recover-password",
        page: ForgottenPassword,
        title: `${baseTitle}Recover password`,
        roles: ["ROLE_PUBLIC"]
    },
    RECOVER_PASSWORD_TOKEN: {
        path: "/recover-password/:token",
        page: RecoverPassword,
        title: `${baseTitle}Recover password`,
        roles: ["ROLE_PUBLIC"]
    }
}
