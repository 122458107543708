import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/holiday`;

export function apiList(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    let query = "";
    if (props.route) { query += `&route=${props.route}`; }

    return axios.get(`${baseUrl}/list${query}`, config)
}

export function apiCreate(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${baseUrl}/create`, data, config)
}

export function apiDelete(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.delete(`${baseUrl}/delete/${data.date}`, config)
}
