import {Grid, IconButton} from "@mui/material";
import InputAutoComplete from "../../../../components/Inputs/InputAutoComplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DndElements from "../../../../components/DndElements";
import React, {useEffect, useState} from "react";
import idAutoincrement from "../../../../utils/idAutoincrement";
import LineDnd from "./LineDnd";

export default function FilterVuelta (props) {

    const {
        setState,
        state,
        stop,
        setListaParadasVuelta,
        listaPararadasVuelta,
        setUpdateListVuelta,
        updateListVuelta
    } = props

    const [idStopVueltaAutoComplete,setIdStopVueltaAutoComplete] = useState({id:"",name:""})

    const addStopVuelta= (id) => {
        if(id){
            setState({...state, idStopVuelta: ""})

            let index = idAutoincrement(listaPararadasVuelta)
            let name = stop.find(item => item.id === id)?.name

            setListaParadasVuelta([
                ...listaPararadasVuelta, {
                    "id":index.toString(),
                    "idStop":id,
                    "stopName": name,
                    "content": null
                }
            ])

            setUpdateListVuelta(true)
        }
    }

    /**
     * Nos petmite tener el código del html actualizado
     */
    useEffect(() => {
        if(updateListVuelta){
            let temp=listaPararadasVuelta?.map((item,key) => {
                return {
                    ...item,
                    content: <LineDnd
                        stopName={item.stopName}
                        id={item.id}
                        list={listaPararadasVuelta}
                        setList={setListaParadasVuelta}
                        setUpdateList={setUpdateListVuelta}
                    />
                }
            })
            setUpdateListVuelta(false)
            setListaParadasVuelta(temp)
        }

    }, [updateListVuelta,listaPararadasVuelta])

    return(
        <Grid item xs={12} md={6} lg={6}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <h4 className="p-0 mt-0">RECORRIDO DE VUELTA</h4>
                </Grid>
                <Grid item xs={8}>
                    {/* <InputSelect
                        label="Parada VUELTA"
                        name="idStopVuelta"
                        onChange={handleChange}
                        value={state?.idStopVuelta}
                        options={stop}
                        disabled={state?.idRoute === ""}
                    /> */}
                    <InputAutoComplete
                        label="Parada VUELTA"
                        name="idStopVueltaAutoComplete"
                        options={stop}
                        disabled={state?.idRoute === ""}
                        value={idStopVueltaAutoComplete}
                        setValue={setIdStopVueltaAutoComplete}
                    />
                </Grid>
                <Grid item xs={4}>
                    <IconButton color="primary" aria-label="edit ruta" size="small" onClick={() => addStopVuelta(idStopVueltaAutoComplete.id)}>
                        <AddCircleIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <DndElements
                        direction="vertical"
                        elementDnd={listaPararadasVuelta}
                        showPosition={true}
                        setElementDnd={setListaParadasVuelta}
                    />
                </Grid>

            </Grid>
        </Grid>
    )
}
