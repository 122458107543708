import {IconButton} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import React from "react";

export default function LineDnd(props) {

    const {stopName, id, list, setList, setUpdateList, loadingStop} = props

    // Eliminamos el elemento de la lista
    const handleDelete = (position) => {
        let temp = list
        list.forEach((item, index) => {
            if (item.id === position) {
                temp.splice(index,1)
            }
        })

        setUpdateList(true)
        // Eliminamos el contenido para actualizar las posiciones de la vista
        setList([])
        // Actualizamos la lista
        setList(temp)
    }

    return(
        <>
            <span style={{marginLeft:10}}>
                {stopName}
            </span>
            <IconButton
                style={{float: "right", marginTop:"-5px"}}
                color="primary"
                aria-label="edit ruta"
                size="small"
                onClick={() => handleDelete(id)}
            >
                <RemoveCircleIcon />
            </IconButton>
        </>
    )
}
