import React, {useState} from "react";
import {Button, Fade, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";

const language = {
    en: {nativeName: 'English'},
    es: {nativeName: 'Español'}
}

export default function MenuLanguage() {

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (lng) => {
        if (lng) { void i18n.changeLanguage(lng) }
        setAnchorEl(null)
    }

    const {i18n} = useTranslation()

    return (
        <div className="menu-language">
            <Button
                id="language-button"
                aria-controls="menu-language"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={{color: "black"}}
            >
                {i18n.resolvedLanguage}
            </Button>
            <Menu
                id="menu-language"
                MenuListProps={{
                    'aria-labelledby': 'language-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {Object.keys(language).map((item) => (
                    <MenuItem key={item} onClick={() => handleClose(item)}>{language[item].nativeName}</MenuItem>
                ))}
            </Menu>
        </div>
    )
}
