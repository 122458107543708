import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {LoadingButton} from "@mui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

/**
 * Muestra un modal
 *
 * ## PROPS
 * - **title** *string*
 * - **btnOk** *string*
 * - **btnCancel** *string*
 * - **open**
 * - **setOpen**
 * - **handleClick** *function*
 * - **loading** *bool*
 */
export default function Modal(props) {

    const {title, btnOk, btnCancel, open, setOpen, handleClick, loading} = props

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} style={{minWidth: "170px"}}>{btnCancel}</Button>
                    <LoadingButton
                        type="button"
                        loading={loading}
                        disabled={loading}
                        loadingPosition="start"
                        variant="contained"
                        size="small"
                        onClick={handleClick}
                        style={{minWidth: "170px"}}
                    >
                        {btnOk}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}
