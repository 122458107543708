import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid'
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import PendingIcon from '@mui/icons-material/Pending'; 
    
function EditToolbar(props) {
    const { rows, setRows, isSaving } = props;

    const handleClick = () => {
        const id = rows.length + 1;
        setRows((oldRows) => [...oldRows, { id, name: ''}]);
       
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
            {isSaving && (<>
            <PendingIcon color="primary" style={{marginRight:"5px"}}/> Saving records ...
            </>
            )}
        </GridToolbarContainer>
    );
}


export default function TableEditHour(props) {
   
    const { rows, columns, setRows, isSaving } = props;

    const handleDeleteClick = (id) => () => {
        let temp=rows.filter((row) => row.id !== id);
        let temp2=[];

        temp.forEach((element, index) => {
              
            element.id=index+1;
            temp2.push(element)
        });

        setRows(temp2)
       
    };

    const columnsFix = [
      
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            cellClassName: 'actions',
            getActions: ({ id }) => {
                
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    /* Unir arrays */
    const columnsEnd = [...columns, ...columnsFix];

    const handleCommit = (e) =>
    {  
        var reg = /(?:[01]\d|2[0123]):(?:[012345]\d$)/g;
        var valido = null;
        
        if(e.value != undefined)
        {
            valido=e.value.match(reg);
        }
        
        const array = rows.map( r=>{

            if(r.id==e.id)
            {
                if(valido!=null || e.value?.length==0) return {...r,[e.field]:e.value}
                else return {...r}
            }else
            {
                return {...r}
            }
        });
        
        setRows(array)
         
    }


    return (
        <>
        <DataGrid
        hideFooter={true}
        sx={{
            border: 0,
            minHeight: '400px'
        }}
        rows={rows}
        columns={columnsEnd}
        onCellEditCommit={handleCommit}
        components={{
            Toolbar: EditToolbar,
        }}
        isCellEditable={(params) => !isSaving}
        componentsProps={{
            toolbar: { rows, setRows, isSaving },
        }}
      />
      </>
    );
}