import * as React from 'react';
import {useState, useEffect} from 'react';
import { DataGrid, GridToolbarContainer, GridActionsCellItem } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import PendingIcon from '@mui/icons-material/Pending'; 
    
function EditToolbar(props) {
    const { isSaving } = props;

    return (
        <GridToolbarContainer>
            {isSaving && (<>
            <PendingIcon color="primary" style={{marginRight:"5px"}}/> Saving records ...
            </>
            )}
        </GridToolbarContainer>
    );
}


export default function TableEditRate(props) {
   
    const { rows, columns, setRows, isSaving } = props;
    let [dynamicHeight, setDynamicHeight] = useState('600px');

    const handleDeleteClick = (id) => () => {
        
        let temp=rows;
        let temp2=[];
        
        temp.forEach((element,index) => {
              
           
            if(id==element.id)
            {
                for(let i=1; i<columns.length;i++)
                {
                    if(id!=i && i>id)
                    {   
                        let name="name"+i;
                        delete element[name];
                    }
                }
            }
            temp2.push(element)
        });

        setRows(temp2)
       
    };

    const columnsFix = [
      
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            cellClassName: 'actions',
            getActions: ({ id }) => {
                
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    /* Unir arrays */
    const columnsEnd = [...columns, ...columnsFix];

    const handleCommit = (e) =>
    {  
        let reg = /(^\d+(\.\d{1,2})?$|^[x|X]$)/g;
        let valido = false;
        let nameId = parseInt(e.field.slice(4))
        
        
        if(nameId!==e.id)
        {
            if(e.value != undefined)
            {
                valido=reg.test(e.value);
            }
        }
        
            const array = rows.map( r=>{

                if(r.id==e.id)
                {
                    if(valido && e.value?.length>0) return {...r,[e.field]:e.value}
                    else return {...r}
                }else
                {
                    return {...r}
                }
            });

        setRows(array)
        

    }

    useEffect(() => {
        
        if (rows.length > 0) {
            setDynamicHeight(Math.min(rows.length * 6 + 10, 80) + 'vh')
        }
        else
        {
            setDynamicHeight('400px');
        }
    }, [rows]) // eslint-disable-line react-hooks/exhaustive-deps

    


    return (
        <>
        {/* <div>{JSON.stringify(rows)}</div> */}
        <DataGrid
        hideFooter={true}
        sx={{
            border: 0,
            minHeight:dynamicHeight
        }}
        rows={rows}
        columns={columnsEnd}
        onCellEditCommit={handleCommit}
        components={{
            Toolbar: EditToolbar,
        }}
        isCellEditable={(params) => !isSaving}
        componentsProps={{
            toolbar: { isSaving },
        }}
      />
      </>
    );
}