import React, {useEffect, useState} from "react";
import {apiLogin} from "../../../services/Auth/login";
import useAuth from "../../../hooks/useAuth";
import {Link, useHistory} from "react-router-dom";
import {TextField} from "@mui/material";
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import MsgError from "../../../components/MsgError";
import {LoadingButton} from "@mui/lab";
import {RouteAuth} from "../../../routes/RouteAuth";
import {RouteAdmin} from "../../../routes/RouteAdmin";
import MsgSuccess from "../../../components/MsgSuccess";
import jwtDecode from "jwt-decode";
import {RouteUser} from "../../../routes/RouteUser";

const theme = createTheme();

export default function LoginForm() {

    const {login} = useAuth()
    const history = useHistory()
    const params = new URLSearchParams(window.location.search)

    const [state, setState] = useState(initialValues())
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loading, setLoading] = useState(false)

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        setLoading(true)
        event.preventDefault()

        setMsgError([])
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            return
        }

        apiLogin(state).then(resp => {
            startLogin(resp.data?.token)
        }).catch(err => {
            if (err.response?.data?.msg) {
                setMsgError(err.response?.data?.msg)
            } else {
                setMsgError(["Parece que algo ha ido mal, inténtalo más tarde o contacta con el administrador."])
            }
            setLoading(false)
        })
    }

    const startLogin = async (token) => {
        if (jwtDecode(token).roles[0] === "ROLE_ADMIN") {
            await login({
                primaryUser: token,
                secondaryUser: token,
                initial: true
            })
            history.push(RouteAdmin.INCIDENTS_MANAGEMENT.path)
            setLoading(false)

        } else {
            await login({
                primaryUser: token,
                initial: true
            })
            history.push(RouteUser.ROOT.path)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    return (
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>

                        <Box component="form" noValidate sx={{mt: 3}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <MsgError msg={msgError}/>
                                    <MsgSuccess msg={msgSuccess}/>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="username"
                                        label="Email Address"
                                        name="username"
                                        autoComplete="email"
                                        onChange={handleChange}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <LoadingButton
                                        type="button"
                                        loading={loading}
                                        loadingPosition="start"
                                        variant="contained"
                                        fullWidth
                                        onClick={handleSubmit}
                                        sx={{mt: 1, mb: 2}}
                                    >
                                        Sign In
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs>
                                    <Link to={RouteAuth.RECOVER_PASSWORD.path}>
                                        Forgot password?
                                    </Link>
                                </Grid>
                                {/* <Grid item>
                                    <Link to={RouteAuth.SIGNUP.path}>
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid> */}
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        )
}

function initialValues() {
    return {
        username: "", password: ""
    }
}

function validationSchema(schema) {
    let error = []

    if (!schema.username) {
        error.push("El correo electrónico es obligatorio")
    }
    if (!schema.password) {
        error.push("La contraseña es obligatoria")
    }

    return error
}
