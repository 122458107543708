import React, {useEffect, useState} from "react";
import {apiList, apiDelete, apiDuplicate} from "../../../../services/Admin/schedule";
import moment from "moment";
import {Button, IconButton, Grid, Paper, Divider} from "@mui/material";
import MsgSuccess from "../../../../components/MsgSuccess";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Table from "../../../../components/Table";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import InputSelect from "../../../../components/Inputs/InputSelect";

import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalRemove from "../../../../components/Modal/ModalRemove";
import ModalCreate from "../../../../components/Modal/ModalCreate";

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {apiList as apiListRoute} from "../../../../services/Admin/route";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function Management() {
    const [rows, setRows] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [openModalRemove, setOpenModalRemove] = useState(false)
    const [isRemoveConfirm, setIsRemoveConfirm] = useState(false)
    const [idRemove, setIdRemove] = useState(null)
    const [openModalCreate, setOpenModalCreate] = useState(false)
    const [isCreateConfirm, setIsCreateConfirm] = useState(false)
    const [idCreate, setIdCreate] = useState(null)
    const [newName, setNewName] = useState(null)

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

   
    
    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
        if (state.sort.field === "active") { field = "isActive" }
       
        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.idRoute) { params.idRoute = state.idRoute }
    
      
        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc,
                    active: parseInt(doc.isActive) ? "Si" : "No",
                     dateFrom: moment.unix(doc.dateFrom).format("D/M/Y HH:mm"),
                     dateTo: moment.unix(doc.dateTo).format("D/M/Y HH:mm"),
                     
                   
                }
            })
           
            setRows(temp) 
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    useEffect(() => {
        if (isRemoveConfirm) {
            
            apiDelete(idRemove).then(resp => {
                setMsgSuccess(resp.data?.msg)
                setIsRemoveConfirm(false)
                setIdRemove(null)
                getItemsData()
            }).catch(err => {
                setMsgError(err.response?.data?.msg)
                setIsRemoveConfirm(false)
                setIdRemove(null)
                //getItemsData()           
            })   
        }
    }, [isRemoveConfirm])

    useEffect(() => {
        if (isCreateConfirm) {

            let data = {id:idCreate, name: newName}
            
            apiDuplicate(data).then(resp => {
                setMsgSuccess(resp.data?.msg)
                setIsCreateConfirm(false)
                setIdCreate(null)
                setNewName(null)
                getItemsData()
            }).catch(err => {
                setMsgError(err.response?.data?.msg)
                setIsCreateConfirm(false)
                setIdCreate(null)
                setNewName(null)
                //getItemsData()           
            })   
        }
    }, [isCreateConfirm])

    const removeRow = (id) => {
        setIdRemove(id)
        setOpenModalRemove(true)
    }

    const copySchedule = (id) => {
        setIdCreate(id)
        setOpenModalCreate(true)
    }

    return (
        <>
            <ModalRemove
                open={openModalRemove}
                setOpen={setOpenModalRemove}
                setIsRemoveConfirm={setIsRemoveConfirm}
            />
            <ModalCreate
                open={openModalCreate}
                setOpen={setOpenModalCreate}
                setIsCreateConfirm={setIsCreateConfirm}
                setNewName={setNewName}
            />
            <HeaderPage title="Horarios">
                <Button
                    variant="contained"
                    color="primary"
                    style={{float: "right", margin: "20px 0px"}}
                    href={RouteAdmin.SCHEDULE_CREATE.path}
                >
                    + Añadir
                </Button>
            </HeaderPage>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgSuccess msg={msgSuccess}/>
                    <MsgError msg={msgError}/>
                </Grid>

                <Grid item xs={12}>

                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>

                        <Filters
                            state={state}
                            setState={setState}
                        />

                        <Table
                            columns={tableColumns(removeRow, copySchedule)}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "asc" },
        name:"",
        idRoute:"",
    }
}

function tableColumns(removeRow, copySchedule) {
    return [
        // {
        //     field: "id",
        //     headerName: "ID",
        //     maxWidth: 80
        // },
        {
            field: "routeName",
            headerName: "LINEA",
            flex: 1
        },
        {
            field: "name",
            headerName: "NOMBRE",
            flex:1
        },
        {
            field: "lunes",
            headerName: "L",
            maxWidth: 10,
            renderCell: (params) => {
                return (<>{params.value ? <CheckIcon style={{ color: '#008000' }}/> : <CloseIcon style={{ color: '#ff0000' }}/>}</>)
                       
            },
        },
        {
            field: "martes",
            headerName: "M",
            maxWidth: 10,
            renderCell: (params) => {
                return (<>{params.value ? <CheckIcon style={{ color: '#008000' }}/> : <CloseIcon style={{ color: '#ff0000' }}/>}</>)
                       
            },
        },
        {
            field: "miercoles",
            headerName: "X",
            maxWidth: 10,
            renderCell: (params) => {
                return (<>{params.value ? <CheckIcon style={{ color: '#008000' }}/> : <CloseIcon style={{ color: '#ff0000' }}/>}</>)
                       
            },
        },
        {
            field: "jueves",
            headerName: "J",
            maxWidth: 10,
            renderCell: (params) => {
                return (<>{params.value ? <CheckIcon style={{ color: '#008000' }}/> : <CloseIcon style={{ color: '#ff0000' }}/>}</>)
                       
            },
        },
        {
            field: "viernes",
            headerName: "V",
            maxWidth: 10,
            renderCell: (params) => {
                return (<>{params.value ? <CheckIcon style={{ color: '#008000' }}/> : <CloseIcon style={{ color: '#ff0000' }}/>}</>)
                       
            },
        },
        {
            field: "sabado",
            headerName: "S",
            maxWidth: 10,
            renderCell: (params) => {
                return (<>{params.value ? <CheckIcon style={{ color: '#008000' }}/> : <CloseIcon style={{ color: '#ff0000' }}/>}</>)
                       
            },
        },
        {
            field: "domingo",
            headerName: "D",
            maxWidth: 10,
            renderCell: (params) => {
                return (<>{params.value ? <CheckIcon style={{ color: '#008000' }}/> : <CloseIcon style={{ color: '#ff0000' }}/>}</>)
                       
            },
        },
        {
            field: "festivo",
            headerName: "FESTIVO",
            maxWidth: 25,
            renderCell: (params) => {
                return (<>{params.value ? <CheckIcon style={{ color: '#008000' }}/> : <CloseIcon style={{ color: '#ff0000' }}/>}</>)
                       
            },
        },
       
        {
            field: "active",
            headerName: "ACTIVO",
            maxWidth: 20,
            renderCell: (params) => {
                return (<>{params.value ? <CheckIcon style={{ color: '#008000' }}/> : <CloseIcon style={{ color: '#ff0000' }}/>}</>)
                       
            },
        },
        {
            field: "dateFrom",
            headerName: "INICIO",
           
        },
        {
            field: "dateTo",
            headerName: "FIN",
            
        },
        {
            field: "actions",
            headerName: "ACC",
            flex:1,
            renderCell: (params) => {
                return (<><Link to={`${RouteAdmin.SCHEDULE_EDIT.path}/${params.row.id}`}>
                            <IconButton color="primary" aria-label="edit horario" size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>

                        {/* <IconButton color="primary" aria-label="copy horario" size="small" onClick={() => copySchedule(params.row.id)}>
                            <ContentCopyIcon fontSize="inherit"/>
                        </IconButton> */}

                          <IconButton color="primary" aria-label="delete horario" size="small" onClick={() => removeRow(params.row.id)}>
                            <DeleteIcon fontSize="inherit"/>
                        </IconButton></>)
            },
        },
    ]
}

function Filters(props) {

    const {state, setState} = props
    const {t} = useTranslation()
    const [route, setRoute] = useState([])


    useEffect(() => {void getRoute()}, [])

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const getRoute = () => {

        apiListRoute({
            field: "name",
            sort: "asc"
        }).then(resp => {
            setRoute(resp.data?.data)
        }).catch(err => {
            setLoading(false)
            setMsgError(err.response?.data?.msg)
        })
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
            <Grid item xs={6}>
            <InputSelect
                label="Línea"
                name="idRoute"
                onChange={handleChange}
                value={state?.idRoute}
                options={route || []}
            />
            </Grid>
             <Grid item xs={3}>
                <Button onClick={() => setState(initialValues())}>
                <HighlightOffIcon/>
                </Button>
            </Grid>
        </Grid>

    )
}
