import idAutoincrement from "../../../../utils/idAutoincrement";
import {Grid, IconButton} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DndElements from "../../../../components/DndElements";
import React, {useEffect, useState} from "react";
import LineDnd from "./LineDnd";
import InputAutoComplete from "../../../../components/Inputs/InputAutoComplete";

export default function FilterIda (props) {

    const {
        state,
        setState,
        stop,
        listaPararadasIda,
        setListaParadasIda,
        setUpdateListIda,
        updateListIda,

    } = props

    const [idStopIdaAutoComplete,setIdStopIdaAutoComplete] = useState({id:"",name:""})

    const addStopIda = (id) => {
        if(id){
            setState({...state, idStopIda: ""})

            let index = idAutoincrement(listaPararadasIda)
            let name = stop.find(item => item.id === id)?.name

            setListaParadasIda([
                ...listaPararadasIda, {
                    "id":index.toString(),
                    "idStop":id,
                    "stopName": name,
                    "content": null
                }
            ])

            setUpdateListIda(true)
        }

    }

    /**
     * Nos petmite tener el código del html actualizado
     */
    useEffect(() => {
        if(updateListIda){
            let temp=listaPararadasIda?.map((item,key) => {
                return {
                    ...item,
                    content: <LineDnd
                        stopName={item.stopName}
                        id={item.id}
                        list={listaPararadasIda}
                        setList={setListaParadasIda}
                        setUpdateList={setUpdateListIda}
                    />
                }
            })
            setUpdateListIda(false)
            setListaParadasIda(temp)
        }

    }, [updateListIda,listaPararadasIda])

    return(
        <Grid item xs={12} md={6} lg={6}>
            <Grid container spacing={3}>

                <Grid item xs={12} md={12} lg={12}>
                    <h4 className="p-0 mt-0">RECORRIDO DE IDA</h4>
                </Grid>
                <Grid item xs={8}>
                    {/* <InputSelect
                        label="Parada IDA"
                        name="idStopIda"
                        onChange={handleChange}
                        value={state?.idStopIda}
                        options={stop}
                        disabled={state?.idRoute === ""}
                    /> */}
                     <InputAutoComplete
                        label="Parada IDA"
                        name="idStopIdaAutoComplete"
                        options={stop}
                        disabled={state?.idRoute === ""}
                        value={idStopIdaAutoComplete}
                        setValue={setIdStopIdaAutoComplete}
                    />
                </Grid>
                <Grid item xs={4}>
                    <IconButton color="primary" aria-label="edit ruta" size="small" onClick={() => addStopIda(idStopIdaAutoComplete.id)}>
                        <AddCircleIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    <DndElements
                        direction="vertical"
                        elementDnd={listaPararadasIda}
                        showPosition={true}
                        setElementDnd={setListaParadasIda}
                    />
                </Grid>

            </Grid>
        </Grid>
    )
}
