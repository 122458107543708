import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/route`;

export function apiList(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    let query = "";
    if (props.name) { query += `&name=${props.name}`; }
    if (props.limit) { query += `&limit=${props.limit}`; }
    if (props.offset) { query += `&offset=${props.offset}`; }
    if (props.trips) { query += `&trips=${props.trips}`; }
    if (props.rates) { query += `&rates=${props.rates}`; }

    return axios.get(`${baseUrl}/list?1=1&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiCreate(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${baseUrl}/create`, data, config)
}

export function apiUpdate(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.put(`${baseUrl}/update/${data.id}`, data, config)
}

export function apiDelete(id) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.delete(`${baseUrl}/delete/${id}`, config)
}

export function apiGetRoute(id) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${baseUrl}/${id}`, config)
}
