import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MsgError from "../MsgError";

export default function ModalCreate(props) {
    const {open, setOpen, setIsCreateConfirm, setNewName} = props
    const [value, setValue] = useState("")
    const [error, setError] = useState([])

    const handleClose = () => {
        setOpen(false)
    }

    const handleCloseRemove = () => {
        setError([])
        if (value.length <=0) {
            setError(["Debe escribir un nombre para el nuevo horario ..."])
        } else {
            setIsCreateConfirm(true)
            setOpen(false)
            setNewName(value)
            setValue("")
        }
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Duplicar horario</DialogTitle>
                <DialogContent>
                    <MsgError msg={error} />

                    <DialogContentText>
                       Escriba el nombre del nuevo horario:
                    </DialogContentText>

                    <TextField
                        autoFocus
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="standard"
                        placeholder={'Nombre'}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                    <Button onClick={handleCloseRemove}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
