import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

import {apiCreate, apiGetStop, apiUpdate} from "../../../../services/Admin/stop";
import InputText from "../../../../components/Inputs/InputText";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import InputSelect from "../../../../components/Inputs/InputSelect";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Grid, Paper, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import LoadingBar from "../../../../components/LoadingBar";

import {apiList as apiListCity} from "../../../../services/Admin/city";


export default function Edit() {
    const {id} = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const params = new URLSearchParams(window.location.search)

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)

    const [checkedAccess, setCheckedAccess] = useState(true)

    const myRef = useRef(null)

    const [city, setCity] = useState([])

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    // Llamamos a la API para obtener los datos de la parada
    useEffect(() => {
        void getCity()
        if (id) {
            void getStop()
        } else {
            setLoadingGetInfo(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const getCity = () => {

        apiListCity({
            field: "name",
            sort: "asc"
        }).then(resp => {
            setCity(resp.data?.data)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }

   
    const getStop = () => {
        setMsgError([])

        apiGetStop(id).then(resp => {

            // resp.data.data.province=resp.data.data.idProvince;
            
            setState(initialValues(resp.data?.data))
            
            setLoadingGetInfo(false)
            if (resp.data?.data?.isActive == 1)  {
                setCheckedAccess(true)
            } else {
                setCheckedAccess(false)
            }
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    // const handleChange = (event) => {
    //     state["items"][event.target.name] = event.target.value
    //     setState({...state})
    // }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
        if (event.target.checked) {
            setCheckedAccess(true)
        } else {
            setCheckedAccess(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        if (id) {
            void updateStop()
        } else {
            void createStop()
        }
    }

    const updateStop = () => {
        apiUpdate(state).then(resp => {
            // setMsgSuccess(resp.data?.msg)
            // setLoading(false)
            // myRef.current.scrollIntoView()
            history.push(`${RouteAdmin.STOP_MANAGEMENT.path}?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createStop = () => {
        apiCreate(state).then(resp => { 
            //history.push(`${RouteAdmin.STOP_EDIT.path}/${resp.data?.id}?msg=${resp.data?.msg}`)
            history.push(`${RouteAdmin.STOP_MANAGEMENT.path}?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }


    return (
        <>
            <div ref={myRef}></div>
            <HeaderPage title="Configurar Parada" urlBack={RouteAdmin.STOP_MANAGEMENT.path}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <LoadingBar loading={loadingGetInfo} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">{t('user.settings')}</h4>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12}>
                                        <InputSelect
                                            label="Poblacion"
                                            name="idCity"
                                            required
                                            onChange={handleChange}
                                            value={state?.idCity}
                                            options={city}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label="Nombre"
                                            required
                                            name="name"
                                            value={state?.name}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>

                                    <InputText
                                            label="Longitud IDA"
                                            name="longIda"
                                            required
                                            value={state?.longIda}
                                            onChange={handleChange}
                                        />

                                    </Grid>

                                    <Grid item xs={12}>

                                    <InputText
                                            label="Latitud IDA"
                                            name="latIda"
                                            required
                                            value={state?.latIda}
                                            onChange={handleChange}
                                        />

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>

                                    <InputText
                                            label="Longitud VUELTA"
                                            name="longVuelta"
                                            required
                                            value={state?.longVuelta}
                                            onChange={handleChange}
                                        />

                                    </Grid>

                                    <Grid item xs={12}>

                                    <InputText
                                            label="Latitud VUELTA"
                                            name="latVuelta"
                                            required
                                            value={state?.latVuelta}
                                            onChange={handleChange}
                                        />

                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputSwitch
                                            label="Activo"
                                            name="isActive"
                                            value={state?.isActive}
                                            checked={checkedAccess}
                                            onChange={handleChangeChecked}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? 'Modificar' : 'Crear'}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    
    return {
        id: props?.id || "",
        name: props?.name || "",
        idCity: props?.idCity || "",
        latIda: props?.latIda || "",
        longIda: props?.longIda || "",
        latVuelta: props?.latVuelta || "",
        longVuelta: props?.longVuelta || "",
        isActive: props?.isActive || true,
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.idCity) {
        error.push("La poblacion es obligatoria")
    }
    if (!schema.name) {
        error.push("El nombre es obligatorio")
    } 
    if (!schema.longIda && !schema.longVuelta) {
        error.push("Una longitud de IDA o VUELTA es obligatoria")
    }
   
    if (!schema.latIda && !schema.latVuelta) {
        error.push("Una latitud de IDA o VUELTA es obligatoria")
    }

    if (schema.longIda && !schema.latIda || !schema.longIda && schema.latIda) {
        error.push("Rellene los datos de IDA o VUELTA o AMBOS")
    }else if(schema.longVuelta&& !schema.latVuelta || !schema.longVuelta && schema.latVuelta)
    {
        error.push("Rellene los datos de IDA o VUELTA o AMBOS")
    }
   

    return error
}
