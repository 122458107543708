import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

import {apiCreate, apiUpdate, apiGetSchedule, apiGetScheduleServices} from "../../../../services/Admin/schedule";
import InputText from "../../../../components/Inputs/InputText";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import InputSelect from "../../../../components/Inputs/InputSelect";
import DatePickerSimple from "../../../../components/Inputs/DatePickerSimple";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Grid, Paper, Checkbox,FormControlLabel, Box, Tab} from "@mui/material";
import { TabPanel, TabContext, TabList } from '@mui/lab';
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import LoadingBar from "../../../../components/LoadingBar";
import TableEditHour from "../../../../components/TableEditHour";
import {apiList as apiListRoute} from "../../../../services/Admin/route";
import {apiList as apiListTrip} from "../../../../services/Admin/trip";
import moment from "moment";



export default function Edit() {
    const {id} = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const params = new URLSearchParams(window.location.search)

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(true)
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [iniciaLoading, setIniciaLoading] = useState(true)

    const [checkedAccessActivo, setCheckedAccessActivo] = useState(true)
    const [checkedAccessFestivo, setCheckedAccessFestivo] = useState(false)

    const [checkedAccessLunes, setCheckedAccessLunes] = useState(false)
    const [checkedAccessMartes, setCheckedAccessMartes] = useState(false)
    const [checkedAccessMiercoles, setCheckedAccessMiercoles] = useState(false)
    const [checkedAccessJueves, setCheckedAccessJueves] = useState(false)
    const [checkedAccessViernes, setCheckedAccessViernes] = useState(false)
    const [checkedAccessSabado, setCheckedAccessSabado] = useState(false)
    const [checkedAccessDomingo, setCheckedAccessDomingo] = useState(false)

    const [rowsIda, setRowsIda] = useState([])
    const [rowsVuelta, setRowsVuelta] = useState([])
    const [colParadasIda, setColParadasIda] = useState([])
    const [colParadasVuelta, setColParadasVuelta] = useState([])

    const myRef = useRef(null)

    const [route, setRoute] = useState([])

    const [valueTabList, setValueTabList] = useState('1');

    const [isSaving, setIsSaving ] = useState(false);

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    // Llamamos a la API para obtener los datos de la parada
    useEffect(() => {
        void getRoute()
        if (id) {
            void getSchedule()
         
        } else {
            setLoadingGetInfo(false)
            setLoading(false);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps



    // useEffect(() => {
    //    if(!loading && id && !iniciaLoading){
    //         const timeOutId = setTimeout(() => {
    //             handleSubmit(false)
    //         }, 60000)
    //         return () => clearTimeout(timeOutId)
    //     }
    // }, [rowsIda, rowsVuelta])

    const getRoute = () => {

        apiListRoute({
            field: "name",
            sort: "asc"
        }).then(resp => {
            setRoute(resp.data?.data)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }

    useEffect(() => {
        if (state.idRoute) {
             // Por si cambia de linea al crear y habia introducido horarios -> reset de horario
            setRowsIda([])
            setRowsVuelta([])
            void getTrip()
        }
    }, [state.idRoute]) // eslint-disable-line react-hooks/exhaustive-deps

    const getTrip = () =>{
        apiListTrip({
            field: "idTrip",
            sort: "asc",
            idTrip:state.idRoute
        }).then(resp => {
           
            // Creamos cols IDA - VUELTA
            // { field: 'name1', headerName: 'Vilanova-sitges', width: 180, editable: true },
            let ida = []
            let vuelta = []
            let i = 1
            let j = 1
            
            state.idTrip=resp.data?.data[0].idTrip

            resp.data?.data.forEach((element, index) => {
              
              if(element.sentido == 0) {
                  ida.push({
                      "field":"name"+i,
                      "headerName": element.stopName,
                      "editable": true,
                      "disableColumnMenu": true,
                      "disableColumnReorder": true,
                      "sortable": false,
                      "flex": 1,
                  })
                  i++;
              } else {
                  vuelta.push({
                    "field":"name"+j,
                    "headerName": element.stopName,
                    "editable": true,
                    "disableColumnMenu": true,
                    "disableColumnReorder": true,
                    "sortable": false,
                    "flex": 1,
                  })
                  j++;
              }
            })

          
            setColParadasIda(ida)
            setColParadasVuelta(vuelta)
            

        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }
   
    const getSchedule = () => {
        setMsgError([])

        apiGetSchedule(id).then(resp => {

            setState(initialValues(resp.data?.data))
        
            setLoadingGetInfo(false)
            if (resp.data?.data?.isActive == 1)  {
                setCheckedAccessActivo(true)
            } else {
                setCheckedAccessActivo(false)
            }
            if (resp.data?.data?.festivo == 1)  {
                setCheckedAccessFestivo(true)
            } else {
                setCheckedAccessFestivo(false)
            }
            if (resp.data?.data?.lunes == 1)  {
                setCheckedAccessLunes(true)
            } else {
                setCheckedAccessLunes(false)
            }
            if (resp.data?.data?.martes == 1)  {
                setCheckedAccessMartes(true)
            } else {
                setCheckedAccessMartes(false)
            }
            if (resp.data?.data?.miercoles == 1)  {
                setCheckedAccessMiercoles(true)
            } else {
                setCheckedAccessMiercoles(false)
            }
            if (resp.data?.data?.jueves == 1)  {
                setCheckedAccessJueves(true)
            } else {
                setCheckedAccessJueves(false)
            }
            if (resp.data?.data?.viernes == 1)  {
                setCheckedAccessViernes(true)
            } else {
                setCheckedAccessViernes(false)
            }
            if (resp.data?.data?.sabado == 1)  {
                setCheckedAccessSabado(true)
            } else {
                setCheckedAccessSabado(false)
            }
            if (resp.data?.data?.domingo == 1)  {
                setCheckedAccessDomingo(true)
            } else {
                setCheckedAccessDomingo(false)
            }
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }

    useEffect(() => {
        
        if (colParadasIda.length>0 && colParadasVuelta.length > 0) {
            getScheduleServices();
        }
    }, [colParadasIda, colParadasVuelta]) // eslint-disable-line react-hooks/exhaustive-deps

    const getScheduleServices = () => {

        if(id)
        {
            setLoading(true)
            setMsgError([])

            apiGetScheduleServices(id).then(resp => {

                setLoadingGetInfo(false)

                let numColsIda=colParadasIda.length;
                let numColsVuelta=colParadasVuelta.length;

                let tempIda = [];
                let tempVuelta=[];

                let tempIdaResult=[];
                let tempVueltaResult=[];

                resp.data.data.forEach((element, index) => {
                    
                    if(element.sentido == 0)
                    {
                        tempIda.push(element)
                    }else
                    {
                        tempVuelta.push(element)
                    }

                })
    
                tempIda.forEach((element, index) => {
                    
                    if(index%numColsIda == 0 && element.sentido == 0)
                    {
                        console.log("pongo id ida");
                        tempIdaResult.push({id:element.pos, name:""})
                    }
                    
                    if(element.value != null)
                    {  
                        let name="name"+element.col.toString();
                        tempIdaResult[element.pos-1]={...tempIdaResult[element.pos-1],[name]:moment.unix(element.value).utcOffset(0, false).format("HH:mm")}
                    }
                   

                })

                tempVuelta.forEach((element, index) => {

                    
                    if(index%numColsVuelta == 0)
                    {
                        console.log("pongo id vuelta");
                        tempVueltaResult.push({id:element.pos, name:""})
                    }
                    
                    if(element.value != null)
                    {  
                        let name="name"+element.col.toString();
                        tempVueltaResult[element.pos-1]={...tempVueltaResult[element.pos-1],[name]:moment.unix(element.value).utcOffset(0, false).format("HH:mm")}
                    }
                    


                })
                
                setRowsIda(tempIdaResult)
                setRowsVuelta(tempVueltaResult)
                setLoading(false)
                setIniciaLoading(false)


            }).catch(err => {
                setLoadingGetInfo(false)
                setMsgError(err.response?.data?.msg)
                setLoading(false)
                setIniciaLoading(false)
            })
        }
        
    }

    useEffect(() => {
        checkHorasIda()
    }, [rowsIda]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        checkHorasVuelta()
    }, [rowsVuelta]) // eslint-disable-line react-hooks/exhaustive-deps


    const checkHorasIda = () =>
    {
        var celdas = document.getElementsByClassName(["MuiDataGrid-cellContent"])
        var numCeldas=celdas.length;
        var hasError=false;

        // if(numCeldas>0)
        // {
           
        //     for (var i = 0; i < (numCeldas-1); i++) { 
        //         console.log("i: "+i + " ci: "+ celdas[i].innerHTML+"  ci+1: "+celdas[i+1].innerHTML+ " %: "+i%colParadasIda.length )
        //         //console.log()
        //         if(celdas[i].innerHTML!="" && celdas[i+1].innerHTML!="" && (i%colParadasIda.length!=colParadasIda.length-1))
        //         {   //console.log("in")
        //             if(moment("1970-1-1 "+celdas[i].innerHTML).utcOffset(0, false).format('x') > moment("1970-1-1 "+celdas[i+1].innerHTML).utcOffset(0, false).format('x')){
        //                 celdas[i+1].style.color = "red";
        //                 hasError=true;
        //                 //console.log("errr i:"+ i+ " celda: " + celdas[i].innerHTML)
        //             }else{
        //                 celdas[i+1].style.color ="";
        //             }
        //         }
           
            
        //     }
        // }
        return hasError
    }

    const checkHorasVuelta = () =>{
        var celdas = document.getElementsByClassName(["MuiDataGrid-cellContent"])
        var numCeldas=celdas.length;
        var hasError = false;

        // if(numCeldas>0)
        // {
            
        //     for (var i = 0; i < numCeldas-1; i++) { 
        //         console.log("i: "+i + " ci: "+ celdas[i].innerHTML+"  ci+1: "+celdas[i+1].innerHTML+ " %: "+i%colParadasIda.length )
        //         if(celdas[i].innerHTML!="" && celdas[i+1].innerHTML!="" && (i%colParadasVuelta.length!=colParadasVuelta.length-1))
        //         {
                    
        //             if(moment("1970-1-1 "+celdas[i].innerHTML).utcOffset(0, false).format('x') > moment("1970-1-1 "+celdas[i+1].innerHTML).utcOffset(0, false).format('x')){
        //                 celdas[i+1].style.color = "red";
        //                 var hasError = true;
        //                 console.log("errr i:"+ i+ " celda: " + celdas[i].innerHTML)
        //             }else{
        //                 celdas[i+1].style.color ="";
        //             }
        //         }
           
            
        //     }
        // }
        return hasError
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        //console.log(state)
        setState({...state})
    }

    // const handleChange = (event) => {
    //     state["items"][event.target.name] = event.target.value
    //     setState({...state})
    // }

    const handleChangeCheckedActivo = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
        if (event.target.checked) {
            setCheckedAccessActivo(true)
        } else {
            setCheckedAccessActivo(false)
        }
    }

    const handleChangeCheckedFestivo = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
        if (event.target.checked) {
            setCheckedAccessFestivo(true)
        } else {
            setCheckedAccessFestivo(false)
        }
    }

    const handleChangeCheckedDays = (event) => {
       
        state[event.target.name] = event.target.checked
        setState({...state})
        if (event.target.checked) {
            switch (event.target.name) 
            {
                case "lunes":     setCheckedAccessLunes(true); break;
                case "martes":    setCheckedAccessMartes(true); break;
                case "miercoles": setCheckedAccessMiercoles(true); break;
                case "jueves":    setCheckedAccessJueves(true); break;
                case "viernes":   setCheckedAccessViernes(true); break;
                case "sabado":    setCheckedAccessSabado(true); break;
                case "domingo":   setCheckedAccessDomingo(true); break;
            }
        } else {
            switch (event.target.name) 
            {
                case "lunes":     setCheckedAccessLunes(false); break;
                case "martes":    setCheckedAccessMartes(false); break;
                case "miercoles": setCheckedAccessMiercoles(false); break;
                case "jueves":    setCheckedAccessJueves(false); break;
                case "viernes":   setCheckedAccessViernes(false); break;
                case "sabado":    setCheckedAccessSabado(false); break;
                case "domingo":   setCheckedAccessDomingo(false); break;
            }
        }
    }

    const handleSubmit = (scrollTop = true) => {
        // event.preventDefault()

        setLoading(true)
        setMsgSuccess([])
        setMsgError([]) 
        setIsSaving(true)

        state.servicesIda=rowsIda;
        state.servicesVuelta=rowsVuelta;
      
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            setIsSaving(false)
            myRef.current.scrollIntoView()
            return
        }

        // Validar que no hay filas vacias

        let numColsIda=colParadasIda.length;
        let numColsVuelta=colParadasVuelta.length;
        let hasValue = false;
        let errorFilaIda =false;
        let errorFilaVuelta =false;

        rowsIda.forEach((element, index) => {
            hasValue = false;
            for(let i=1;i<=numColsIda;i++)
            {
                let key = "name"+i;
                if(key in element)
                {
                    if(element[key]!="")
                    {
                        hasValue=true;
                        break;
                    }
                }
            }
            if(!hasValue)
            {
                errorFilaIda=true;
            }
        });

        rowsVuelta.forEach((element, index) => {
            hasValue = false;
            for(let i=1;i<=numColsVuelta;i++)
            {
                let key = "name"+i;
                if(key in element)
                {
                    if(element[key]!="")
                    {
                        hasValue=true;
                        break;
                    }
                }
            }
            if(!hasValue)
            {
                errorFilaVuelta=true;
            }
        });

        if (errorFilaIda && errorFilaVuelta) {
            setMsgError(["Hay una o más filas vacias en IDA y VUELTA"])
            setLoading(false)
            setIsSaving(false)
             if(scrollTop){ myRef.current.scrollIntoView()}
            return
        }

        if (errorFilaIda) {
            setMsgError(["Hay una o más filas vacias en IDA"])
            setLoading(false)
            setIsSaving(false)
            if(scrollTop){ myRef.current.scrollIntoView()}
            return
        }

        if (errorFilaVuelta) {
            setMsgError(["Hay una o más filas vacias en VUELTA"])
            setLoading(false)
            setIsSaving(false)
            if(scrollTop){ myRef.current.scrollIntoView()}
            return
        }

        // Check Horas Validas

        errorFilaIda=checkHorasIda()
        errorFilaVuelta=checkHorasVuelta()

        if (errorFilaIda) {
            setMsgError(["Hay una o más celdas con horas no válidas en IDA"])
            setLoading(false)
            setIsSaving(false)
            if(scrollTop){ myRef.current.scrollIntoView()}
            return
        }

        if (errorFilaVuelta) {
            setMsgError(["Hay una o más celdas con horas no válidas en VUELTA"])
            setLoading(false)
            setIsSaving(false)
            if(scrollTop){ myRef.current.scrollIntoView()}
            return
        }
        

        if (id) {
            void updateSchedule(scrollTop)
        } else {
            void createSchedule()
        }
    }

    const updateSchedule = (scrollTop) => {
        apiUpdate(state).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            setIsSaving(false)
           if(scrollTop){ myRef.current.scrollIntoView()}
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setIsSaving(false)
           if(scrollTop){ myRef.current.scrollIntoView()}
        })
    }

    const createSchedule = () => {
        apiCreate(state).then(resp => { 
            setIsSaving(false)
            history.push(`${RouteAdmin.SCHEDULE_EDIT.path}/${resp.data?.id}?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setIsSaving(false)
            myRef.current.scrollIntoView()
        })
    }

    const handleChangeTabList = (event, newValue) => {
        
        let hasError = false;
        let msgError = null;

        setMsgError(msgError)

        if(newValue == 2){ // ESTOY EN IDA -> VUELTA
        
            hasError=checkHorasIda();
            msgError=["Hay una o más celdas con horas no válidas en IDA"];
        }
        else
        {
            hasError=checkHorasVuelta();
            msgError=["Hay una o más celdas con horas no válidas en VUELTA"]
        }

        if(!hasError){
            setValueTabList(newValue);
        }else
        {
            setMsgError(msgError)
        }
    };


    return (
        <>
            <div ref={myRef}></div>
            <HeaderPage title="Configurar Horario" urlBack={RouteAdmin.SCHEDULE_MANAGEMENT.path}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <LoadingBar loading={loadingGetInfo} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">{t('user.settings')}</h4>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12}>
                                        <InputSelect
                                            label="Linea"
                                            name="idRoute"
                                            required
                                            onChange={handleChange}
                                            value={state?.idRoute}
                                            options={route}
                                            disabled={state?.idRoute !== "" && id}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label="Nombre"
                                            required
                                            name="name"
                                            value={state?.name}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                   

                                   

                                   

                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <InputSwitch
                                            label="Activo"
                                            name="isActive"
                                            value={state?.isActive}
                                            checked={checkedAccessActivo}
                                            onChange={handleChangeCheckedActivo}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputSwitch
                                            label="Aplica en Festivos"
                                            name="festivo"
                                            value={state?.festivo}
                                            checked={checkedAccessFestivo}
                                            onChange={handleChangeCheckedFestivo}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} style={{textAlign:"center"}}>
                                
                                    <FormControlLabel control={
                                        <Checkbox
                                        name="lunes"
                                        value={state?.lunes}
                                        checked={checkedAccessLunes}
                                        onChange={handleChangeCheckedDays}
                                        />}
                                        label="L"
                                        labelPlacement="top" 
                                    />
                                     <FormControlLabel control={
                                        <Checkbox
                                        name="martes"
                                        value={state?.martes}
                                        checked={checkedAccessMartes}
                                        onChange={handleChangeCheckedDays}
                                        />}
                                        label="M"
                                        labelPlacement="top" 
                                    />
                                     <FormControlLabel control={
                                        <Checkbox
                                        name="miercoles"
                                        value={state?.miercoles}
                                        checked={checkedAccessMiercoles}
                                        onChange={handleChangeCheckedDays}
                                        />}
                                        label="X"
                                        labelPlacement="top" 
                                    />
                                     <FormControlLabel control={
                                        <Checkbox
                                        name="jueves"
                                        value={state?.jueves}
                                        checked={checkedAccessJueves}
                                        onChange={handleChangeCheckedDays}
                                        />}
                                        label="J"
                                        labelPlacement="top" 
                                    />
                                    <FormControlLabel control={
                                        <Checkbox
                                        name="viernes"
                                        value={state?.viernes}
                                        checked={checkedAccessViernes}
                                        onChange={handleChangeCheckedDays}
                                        />}
                                        label="V"
                                        labelPlacement="top" 
                                    />
                                     <FormControlLabel control={
                                        <Checkbox
                                        name="sabado"
                                        value={state?.sabado}
                                        checked={checkedAccessSabado}
                                        onChange={handleChangeCheckedDays}
                                        />}
                                        label="S"
                                        labelPlacement="top" 
                                    />
                                     <FormControlLabel control={
                                        <Checkbox
                                        name="domingo"
                                        value={state?.domingo}
                                        checked={checkedAccessDomingo}
                                        onChange={handleChangeCheckedDays}
                                        />}
                                        label="D"
                                        labelPlacement="top" 
                                    />           
                            </Grid>
                            <Grid item xs={12} md={4} lg={2} style={{marginTop:"15px"}}>
                                <DatePickerSimple 
                                            label="Fecha inicio"
                                            name="dateFrom"
                                            value={state?.dateFrom}
                                            onChange={handleChange}
                                            required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={2} style={{marginTop:"15px"}}>
                                <DatePickerSimple
                                            label="Fecha fin"
                                            name="dateTo"
                                            value={state?.dateTo}
                                            onChange={handleChange}
                                            required                                        
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                            {(state.idRoute!="" && 
                                (
                                <>
                                <Box sx={{ width: '100%' }} >
                                <TabContext value={valueTabList}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList aria-label="Horarios IDA - VUELTA" onChange={handleChangeTabList}>
                                            <Tab label="IDA" value="1"/>
                                            <Tab label="VUELTA"  value="2"/>
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1" index={0} style={{ flexGrow: 1 }}> 
                                        <TableEditHour
                                                    columns={colParadasIda}
                                                    rows={rowsIda}
                                                    setRows={setRowsIda}
                                                    isSaving={isSaving}
                                                    /></TabPanel>
                                    <TabPanel value="2" index={1} style={{ flexGrow: 1 }}> 
                                        <TableEditHour
                                                    columns={colParadasVuelta}
                                                    rows={rowsVuelta}
                                                    setRows={setRowsVuelta}
                                                    isSaving={isSaving}
                                                    />
                                    </TabPanel>
                                </TabContext>
                                </Box>
                                </>
                                )
                            )}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? 'Modificar' : 'Crear'}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
   
    return {
        id: props?.id || "",
        name: props?.name || "",
        idRoute: props?.idRoute || "",
        idTrip: props?.idTrip || "",
        isActive: props?.isActive || true,
        festivo: props?.festivo || false,
        lunes: props?.lunes || false,
        martes: props?.martes || false,
        miercoles: props?.miercoles || false,
        jueves: props?.jueves || false,
        viernes: props?.viernes || false,
        sabado: props?.sabado || false,
        domingo: props?.domingo || false,
        dateFrom: props?.dateFrom || "",
        dateTo: props?.dateTo || "",
        rowsIda: props?.rowsIda || [],
        rowsVuelta:props?.rowsVuelta || [],   
    } 
}


function validationSchema(schema) {

    let error = []

    if (!schema.idRoute) {
        error.push("La linea es obligatoria")
    }
    if (!schema.name) {
        error.push("El nombre es obligatorio")
    } 

    if (!schema.dateFrom) {
        error.push("La Fecha inicio es obligatoria")
    }
    if (!schema.dateTo) {
        error.push("La fecha final es obligatoria")
    }

    return error
}
