import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

import {apiCreate, apiGetCity, apiUpdate} from "../../../../services/Admin/city";
import InputText from "../../../../components/Inputs/InputText";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import InputSelect from "../../../../components/Inputs/InputSelect";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Grid, Paper, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import LoadingBar from "../../../../components/LoadingBar";


export default function Edit() {
    const {id} = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const params = new URLSearchParams(window.location.search)

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)

    const [checkedAccess, setCheckedAccess] = useState(true)

    const myRef = useRef(null)

    const optionsProvince = [{id: 1, name: "Barcelona"}, {id: 2, name: "Tarragona"}]

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    // Llamamos a la API para obtener los datos de la ruta
    useEffect(() => {
        if (id) {
            void getCity()
        } else {
            setLoadingGetInfo(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

   
    const getCity = () => {
        setMsgError([])

        apiGetCity(id).then(resp => {

            resp.data.data.province=resp.data.data.idProvince;
            
            setState(initialValues(resp.data?.data))
            
            setLoadingGetInfo(false)
            if (resp.data?.data?.isActive == 1)  {
                setCheckedAccess(true)
            } else {
                setCheckedAccess(false)
            }
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
        if (event.target.checked) {
            setCheckedAccess(true)
        } else {
            setCheckedAccess(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        if (id) {
            void updateRoute()
        } else {
            void createRoute()
        }
    }

    const updateRoute = () => {
        apiUpdate(state).then(resp => {
            //setMsgSuccess(resp.data?.msg)
            //setLoading(false)
            //myRef.current.scrollIntoView()
            history.push(`${RouteAdmin.CITY_MANAGEMENT.path}?msg=${resp.data?.msg}`) 
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createRoute = () => {
        apiCreate(state).then(resp => { 
           // history.push(`${RouteAdmin.CITY_EDIT.path}/${resp.data?.id}?msg=${resp.data?.msg}`)
            history.push(`${RouteAdmin.CITY_MANAGEMENT.path}?msg=${resp.data?.msg}`) 
        }).catch(err => {
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }


    return (
        <>
            <div ref={myRef}></div>
            <HeaderPage title="Configurar Población" urlBack={RouteAdmin.CITY_MANAGEMENT.path}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <LoadingBar loading={loadingGetInfo} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">{t('user.settings')}</h4>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12}>
                                        <InputSelect
                                            label="Provincia"
                                            name="idProvince"
                                            required
                                            onChange={handleChange}
                                            value={state?.idProvince}
                                            options={optionsProvince}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label="Nombre"
                                            required
                                            name="name"
                                            value={state?.name}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>

                                    <InputText
                                            label="Número de zona"
                                            name="zone"
                                            required
                                            value={state?.zone}
                                            onChange={handleChange}
                                        />

                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputSwitch
                                            label="Activo"
                                            name="isActive"
                                            value={state?.isActive}
                                            checked={checkedAccess}
                                            onChange={handleChangeChecked}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? 'Modificar' : 'Crear'}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
  
    return {
        id: props?.id || "",
        name: props?.name || "",
        idProvince: props?.province || "",
        zone:  props?.zone || "",
        isActive: props?.isActive || true,
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.idProvince) {
        error.push("La provincia es obligatoria")
    }
    if (!schema.name) {
        error.push("El nombre es obligatorio")
    } 
    if (!schema.zone) {
        error.push("El número de zona es obligatorio")
    }

    return error
}
