import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

/**
 * Muestra un selector
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **options** *object*
 * - **required** *bool*
 * - **disabled** *bool*
 *
 * ## EJ
 * options: [{id: 1, name: "Aaaa"}, {id: 2, name: "Bbbbb"}]
 */
export default function InputAutoComplete(props) {

    const {label, name, options, required, disabled, value, setValue} = props
    // console.log("v: "+value)
  
  return (
    <Autocomplete
      
      onChange={(event, newValue) => {

        // console.log(newValue)
        
        if (typeof newValue === 'string') {
          setValue({
            name: newValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
       
        

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option.name;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      freeSolo
      size="small"
      required={required}
      disabled={disabled}
      name={name}
      value={value}
      renderInput={(params) => (
        <TextField {...params} label={label}/>
      )}
    />
  );
}

