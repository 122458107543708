import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/incidents`;

export function apiList(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    let query = "";
    if (props.route) { query += `&route=${props.route}`; }
    if (props.stop) { query += `&stop=${props.stop}`; }
    if (props.dateFrom) { query += `&dateFrom=${props.dateFrom}`; }
    if (props.dateTo) { query += `&dateTo=${props.dateTo}`; }

    return axios.get(`${baseUrl}/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiCreate(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.post(`${baseUrl}/create`, [data], config)
}

export function apiUpdate(data) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.put(`${baseUrl}/update/${data.id}`, [data.state], config)
}

export function apiDelete(id) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.delete(`${baseUrl}/delete/${id}`, config)
}

export function apiGet(id) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.get(`${baseUrl}/${id}`, config)
}
