import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {apiCreate, apiGet, apiUpdate} from "../../../../services/Admin/incidents";
import InputText from "../../../../components/Inputs/InputText";
import InputSelect from "../../../../components/Inputs/InputSelect";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Grid, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import LoadingBar from "../../../../components/LoadingBar";
import DatePickerSimple from "../../../../components/Inputs/DatePickerSimple";
import {apiList as apiListRoute} from "../../../../services/Admin/route";
import {apiGetTripByRouteId} from "../../../../services/Admin/trip";
import {lang} from "../../../../utils/lang";
import clone from "../../../../utils/clone";

export default function Edit() {
    const {id} = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const params = new URLSearchParams(window.location.search)

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [route, setRoute] = useState([])
    const [stop, setStop] = useState([])

    const myRef = useRef(null)

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    // Si no existe el idioma iniciamos el modelo. Es decir, montamos estructura
    useEffect(() => {
        if (!state.trans[state.langSelect]) {
            setState({...state, trans: {...state.trans, [state.langSelect]: clone(state.model)}})
        }
    }, [state.langSelect])

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        setMsgError([])
        void getRoute()
       
        if (id) {
            void getUser()
        } else {
            if (!state.trans[state.langSelect]) {
                setState({...state, loading: false, trans: {...state.trans, [state.langSelect]: clone(state.model)}})
            }
            setLoadingGetInfo(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    
    const getUser = () => {
        apiGet(id).then(resp => {
            setState(initialValues(resp.data?.data))
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const getRoute = () => {

        apiListRoute({
            field: "name",
            sort: "asc"
        }).then(resp => {
            setRoute(resp.data?.data)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }


    useEffect(() => {
        if (state.items.route) {
            void getStop()
        }
    }, [state]) // eslint-disable-line react-hooks/exhaustive-deps

    const getStop = () => {

        apiGetTripByRouteId(state.items.route).then(resp => {
            setStop(resp.data?.data || [])
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeItems = (event) => {
        if(event.target.name=="route")
        {
            state["items"]["stop"] = []
        }
        state["items"][event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeTrans = (event) => {
        state['trans'][state.langSelect][event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        if (id) {
            void updateUser()
        } else {
            void createUser()
        }
    }

    const updateUser = () => {
        apiUpdate({
            id,
            state
        }).then(resp => {
            // setMsgSuccess(resp.data?.msg)
            // setLoading(false)
            // myRef.current.scrollIntoView()
            history.push(`${RouteAdmin.INCIDENTS_MANAGEMENT.path}/?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createUser = () => {
        apiCreate(state).then(resp => {
            // history.push(`${RouteAdmin.INCIDENTS_EDIT.path}/${resp.data?.id}?msg=${resp.data?.msg}`)
            history.push(`${RouteAdmin.INCIDENTS_MANAGEMENT.path}/?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    return (
        <>
            <div ref={myRef}></div>
            <HeaderPage title={t('user.account_config')} urlBack={RouteAdmin.INCIDENTS_MANAGEMENT.path}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <LoadingBar loading={loadingGetInfo} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={8}>
                                <h4 className="p-0 mt-0">Ajustes</h4>
                            </Grid>

                            <Grid item xs={4}>
                                <InputSelect
                                    label={"Idioma"}
                                    name="langSelect"
                                    required
                                    onChange={handleChange}
                                    value={state?.langSelect}
                                    options={lang}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} style={{paddingTop: "0px"}}>
                                        <InputSelect
                                            label="Línea"
                                            name="route"
                                            required
                                            onChange={handleChangeItems}
                                            value={state?.items?.route}
                                            options={route}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputSelect
                                            label="Parada (selector múltiple)"
                                            name="stop"
                                            onChange={handleChangeItems}
                                            value={state?.items?.stop}
                                            options={stop}
                                            multiple={true}
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <DatePickerSimple
                                            label="Fecha inicio"
                                            name="dateFrom"
                                            value={state?.items?.dateFrom}
                                            onChange={handleChangeItems}
                                            hour={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <DatePickerSimple
                                            label="Fecha final"
                                            name="dateTo"
                                            value={state?.items?.dateTo}
                                            onChange={handleChangeItems}
                                            hour={true}
                                        />
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} style={{paddingTop: "0px"}}>
                                        <InputText
                                            label="Descripción incidencia"
                                            required
                                            name="description"
                                            rows={9.5}
                                            value={state?.trans?.[state.langSelect]?.description}
                                            onChange={handleChangeTrans}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? t('Modificar') : t('Crear')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    const model = {
        description: ""
    }

    return {
        langSelect: props?.langSelect || "es",
        items: {
            route: props?.items?.route || "",
            stop: props?.items?.stop || [],
            dateFrom: props?.items?.dateFrom || "",
            dateTo: props?.items?.dateTo || "",
        },
        model: model,
        trans: props?.trans || {}
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.items.route) {
        error.push("La línea es obligatoria")
    }
    if (!schema.items.dateFrom) {
        error.push("La Fecha inicio es obligatoria")
    }
    if (!schema.items.dateTo) {
        error.push("La fecha final es obligatoria")
    }

    if (!schema.trans?.es?.description) {
        error.push("La descripción es obligatoria")
    }

    return error
}
