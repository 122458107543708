import UserManagement from "../pages/Admin/User/Management"
import UserEdit from "../pages/Admin/User/Fields"
import UserCreate from "../pages/Admin/User/Fields"

import RouteManagement from "../pages/Admin/Route/Management"
import RouteCreate from "../pages/Admin/Route/Fields"
import RouteEdit from "../pages/Admin/Route/Fields"

import MediaManagement from "../pages/Admin/Media/Management"
import Testing from "../pages/Admin/Testing"

import IncidentsManagement from "../pages/Admin/Incidents/Management"
import FieldsManagement from "../pages/Admin/Incidents/Fields"

import StopManagement from "../pages/Admin/Stop/Management"
import StopCreate from "../pages/Admin/Stop/Fields"
import StopEdit from "../pages/Admin/Stop/Fields"

import TripManagement from "../pages/Admin/Trip/Management"
import TripCreate from "../pages/Admin/Trip/Fields"
import TripEdit from "../pages/Admin/Trip/Fields"

import ScheduleManagement from "../pages/Admin/Schedule/Management"
import ScheduleCreate from "../pages/Admin/Schedule/Fields"
import ScheduleEdit from "../pages/Admin/Schedule/Fields"

import CalendarManagement from "../pages/Admin/Holiday/Management"
import RatesManagement from "../pages/Admin/Rates/Management"
import RatesCreate from "../pages/Admin/Rates/Fields"
import RatesEdit from "../pages/Admin/Rates/Fields"

import CityManagement from "../pages/Admin/City/Management"
import CityCreate from "../pages/Admin/City/Fields"
import CityEdit from "../pages/Admin/City/Fields"

const baseTitle = "Bus Garraf - "

export const RouteAdmin = {
    USER_MANAGEMENT: {
        path: "/admin/users/management",
        page: UserManagement,
        title: `${baseTitle}User Management`,
        menu: "users",
        roles: ["ROLE_ADMIN"]
    },
    USER_EDIT: {
        path: "/admin/users/edit",
        params: "/:id",
        page: UserEdit,
        title: `${baseTitle}User Edit`,
        menu: "users",
        roles: ["ROLE_ADMIN"]
    },
    USER_CREATE: {
        path: "/admin/users/create",
        page: UserCreate,
        title: `${baseTitle}User Create`,
        menu: "users",
        roles: ["ROLE_ADMIN"]
    },
    ROUTE_MANAGEMENT: {
        path: "/admin/route/management",
        page: RouteManagement,
        title: `${baseTitle}Route Management`,
        menu: "route",
        roles: ["ROLE_ADMIN"]
    },
    ROUTE_CREATE: {
        path: "/admin/route/create",
        page: RouteCreate,
        title: `${baseTitle}Route Create`,
        menu: "route",
        roles: ["ROLE_ADMIN"]
    },
    ROUTE_EDIT: {
        path: "/admin/route/edit",
        params: "/:id",
        page: RouteEdit,
        title: `${baseTitle}Route Edit`,
        menu: "route",
        roles: ["ROLE_ADMIN"]
    },
    MEDIA_MANAGEMENT: {
        path: "/admin/media/management",
        page: MediaManagement,
        title: `${baseTitle}Media Management`,
        menu: "media",
        roles: ["ROLE_ADMIN"]
    },
    TRIP_MANAGEMENT: {
        path: "/admin/trip/management",
        page: TripManagement,
        title: `${baseTitle}Trip Management`,
        menu: "trip",
        roles: ["ROLE_ADMIN"]
    },
    TRIP_CREATE: {
        path: "/admin/trip/create",
        page: TripCreate,
        title: `${baseTitle}Trip Create`,
        menu: "trip",
        roles: ["ROLE_ADMIN"]
    },
    TRIP_EDIT: {
        path: "/admin/trip/edit",
        params: "/:id",
        page: TripEdit,
        title: `${baseTitle}Trip Edit`,
        menu: "trip",
        roles: ["ROLE_ADMIN"]
    },
    STOP_MANAGEMENT: {
        path: "/admin/stop/management",
        page: StopManagement,
        title: `${baseTitle}Stop Management`,
        menu: "stop",
        roles: ["ROLE_ADMIN"]
    },
    STOP_CREATE: {
        path: "/admin/stop/create",
        page: StopCreate,
        title: `${baseTitle}Stop Create`,
        menu: "stop",
        roles: ["ROLE_ADMIN"]
    },
    STOP_EDIT: {
        path: "/admin/stop/edit",
        params: "/:id",
        page: StopEdit,
        title: `${baseTitle}Stop Edit`,
        menu: "stop",
        roles: ["ROLE_ADMIN"]
    },
    SCHEDULE_MANAGEMENT: {
        path: "/admin/schedule/management",
        page: ScheduleManagement,
        title: `${baseTitle}Schedule Management`,
        menu: "schedule",
        roles: ["ROLE_ADMIN"]
    },
    SCHEDULE_CREATE: {
        path: "/admin/schedule/create",
        page: ScheduleCreate,
        title: `${baseTitle}Schedule Create`,
        menu: "schedule",
        roles: ["ROLE_ADMIN"]
    },
    SCHEDULE_EDIT: {
        path: "/admin/schedule/edit",
        params: "/:id",
        page: ScheduleEdit,
        title: `${baseTitle}Schedule Edit`,
        menu: "schedule",
        roles: ["ROLE_ADMIN"]
    },
    HOLIDAY_MANAGEMENT: {
        path: "/admin/holiday/management",
        page: CalendarManagement,
        title: `${baseTitle}Holiday Management`,
        menu: "calendar",
        roles: ["ROLE_ADMIN"]
    },
    INCIDENTS_MANAGEMENT: {
        path: "/admin/incidents/management",
        page: IncidentsManagement,
        title: `${baseTitle}Incidents Management`,
        menu: "incidents",
        roles: ["ROLE_ADMIN"]
    },
    INCIDENTS_CREATE: {
        path: "/admin/incidents/create",
        page: FieldsManagement,
        title: `${baseTitle}Incidents Create`,
        menu: "incidents",
        roles: ["ROLE_ADMIN"]
    },
    INCIDENTS_EDIT: {
        path: "/admin/incidents/edit",
        params: "/:id",
        page: FieldsManagement,
        title: `${baseTitle}Incidents Edit`,
        menu: "incidents",
        roles: ["ROLE_ADMIN"]
    },
    RATES_MANAGEMENT: {
        path: "/admin/rate/management",
        page: RatesManagement,
        title: `${baseTitle}Rates Management`,
        menu: "rates",
        roles: ["ROLE_ADMIN"]
    },
    RATES_CREATE: {
        path: "/admin/rate/create",
        page: RatesCreate,
        title: `${baseTitle}Rates Create`,
        menu: "rates",
        roles: ["ROLE_ADMIN"]
    },
    RATES_EDIT: {
        path: "/admin/rate/edit",
        params: "/:id",
        page: RatesEdit,
        title: `${baseTitle}Rates Edit`,
        menu: "rates",
        roles: ["ROLE_ADMIN"]
    },
    CITY_MANAGEMENT: {
        path: "/admin/city/management",
        page: CityManagement,
        title: `${baseTitle}City Management`,
        menu: "city",
        roles: ["ROLE_ADMIN"]
    },
    CITY_CREATE: {
        path: "/admin/city/create",
        page: CityCreate,
        title: `${baseTitle}City Create`,
        menu: "city",
        roles: ["ROLE_ADMIN"]
    },
    CITY_EDIT: {
        path: "/admin/city/edit",
        params: "/:id",
        page: CityEdit,
        title: `${baseTitle}City Edit`,
        menu: "city",
        roles: ["ROLE_ADMIN"]
    },
    TESTING: {
        path: "/admin/testing",
        page: Testing,
        title: `${baseTitle}Testing`,
        menu: "",
        roles: ["ROLE_ADMIN"]
    }
}
