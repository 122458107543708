import Header from "../Header"
import AdminBar from "../../../../components/Layouts/Admin/AdminBar";

export default function Basic(props) {
    return (
        <div>
            <AdminBar />
            <Header/>
            {props.children}
        </div>
    );
}
