export default function idAutoincrement( obj ) {
    let index = 1
    obj.forEach(element => {
        if (index <= parseInt(element.id)) {
            index = parseInt(element.id) + 1
        }
    })

    return index
}
