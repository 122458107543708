import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

import {apiCreate, apiGetTrip, apiUpdate} from "../../../../services/Admin/trip"
import InputSelect from "../../../../components/Inputs/InputSelect";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Grid, Paper, IconButton} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import LoadingBar from "../../../../components/LoadingBar";

import {apiList as apiListRoute} from "../../../../services/Admin/route";
import {apiList as apiListStop} from "../../../../services/Admin/stop";

import FilterIda from "./FilterIda";
import FilterVuelta from "./FilterVuelta";

export default function Edit() {
    const {id} = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const params = new URLSearchParams(window.location.search)

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)

    const [checkedAccess, setCheckedAccess] = useState(true)

    const myRef = useRef(null)

    const [route, setRoute] = useState([])
    const [stop, setStop] = useState([])

    const [listaPararadasIda, setListaParadasIda] = useState([])
    const [listaPararadasVuelta, setListaParadasVuelta] = useState([])

    const [updateListIda,setUpdateListIda] = useState(false)
    const [updateListVuelta,setUpdateListVuelta] = useState(false)

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    // Llamamos a la API para obtener los datos de la ruta
    useEffect(() => {
        
        if (id) {
            getStop()
            getTrip()
        } else {
            getRoute()
            getStop()
            setLoadingGetInfo(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const getRoute = () => {
        // Lineas sin trips (rutas)
        apiListRoute({
            field: "name",
            sort: "asc",
            trips: "no",
        }).then(resp => {
            setRoute(resp.data?.data)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }

    /**
     * És el listado de los selectores de Ida y Vuelta
     */
    const getStop = () => {
        apiListStop({
            field: "name",
            sort: "asc"
        }).then(resp => {
            setStop(resp.data?.data)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }


    /**
     * A partir del id obtenemos toda la lista de paradas de la ruta
     */
    const getTrip = () => {
        setMsgError([])

        apiGetTrip(id).then(resp => {
            setRoute([{id:resp.data?.data[0].idRoute, name:resp.data?.data[0].routeName}])
            state.idRoute=resp.data?.data[0].idRoute
            state.isActive=resp.data?.data[0].isActive

            // Creamos lista IDA - VUELTA
            let ida = []
            let vuelta = []
            resp.data?.data.forEach((element, index) => {
              if(element.sentido == 0) {
                  ida.push({
                      "id":index.toString(),
                      "idStop":element.idStop,
                      "stopName": element.stopName,
                      "content": element.stopName
                  })
              } else {
                  vuelta.push({
                      "id":index.toString(),
                      "idStop":element.idStop,
                      "stopName": element.stopName,
                      "content": element.stopName
                  })
              }
            })

            setListaParadasIda(ida)
            setListaParadasVuelta(vuelta)
            setUpdateListIda(true)
            setUpdateListVuelta(true)

            if (resp.data?.data[0].isActive == 1)  {
                setCheckedAccess(true)
            } else {
                setCheckedAccess(false)
            }

            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }


    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
        if (event.target.checked) {
            setCheckedAccess(true)
        } else {
            setCheckedAccess(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let tempIda=listaPararadasIda.map(item=>
            {
                return {
                    id: item.idStop,
                }
            }
        )

        let tempVuelta=listaPararadasVuelta.map(item=>
            {
                return {
                    id: item.idStop,
                }
            }
        )

        state["listaIda"]=tempIda
        state["listaVuelta"]=tempVuelta

        delete state["idStopIda"]
        delete state["idStopVuelta"]
        
        setState(state)
     
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        if (id) {
            void updateTrip()
        } else {
            void createTrip()
        }
    }

    const updateTrip = () => {
        apiUpdate(state, id).then(resp => {
            // setMsgSuccess(resp.data?.msg)
            // setLoading(false)
            // myRef.current.scrollIntoView()
            history.push(`${RouteAdmin.TRIP_MANAGEMENT.path}/?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createTrip = () => {
        apiCreate(state).then(resp => { 
            // history.push(`${RouteAdmin.TRIP_EDIT.path}/${resp.data?.id}?msg=${resp.data?.msg}`)
            history.push(`${RouteAdmin.TRIP_MANAGEMENT.path}/?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    return (
        <>
            <div ref={myRef}></div>
            <HeaderPage title="Configurar Ruta" urlBack={RouteAdmin.TRIP_MANAGEMENT.path}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <LoadingBar loading={loadingGetInfo} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">{t('user.settings')}</h4>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12} md={6} lg={6}>
                                        <InputSelect
                                            label="Línea"
                                            name="idRoute"
                                            required
                                            onChange={handleChange}
                                            value={state?.idRoute}
                                            options={route}
                                            disabled={state?.idRoute !== ""}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <InputSwitch
                                            label="Activo"
                                            name="isActive"
                                            value={state?.isActive}
                                            checked={checkedAccess}
                                            onChange={handleChangeChecked}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <FilterIda
                                handleChange={handleChange}
                                state={state}
                                setState={setState}
                                stop={stop}
                                listaPararadasIda={listaPararadasIda}
                                setListaParadasIda={setListaParadasIda}
                                setUpdateListIda={setUpdateListIda}
                                updateListIda={updateListIda}
                            />

                            <FilterVuelta
                                handleChange={handleChange}
                                state={state}
                                setState={setState}
                                stop={stop}
                                setListaParadasVuelta={setListaParadasVuelta}
                                listaPararadasVuelta={listaPararadasVuelta}
                                setUpdateListVuelta={setUpdateListVuelta}
                                updateListVuelta={updateListVuelta}
                            />

                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? 'Modificar' : 'Crear'}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
   
    return {
        idRoute: props?.idRoute || "",
        idStopIda: props?.idStopIda || "",
        idStopVuelta: props?.idStopVuelta || "",
        isActive: props?.isActive || true,
        listaIda: props?.listaIda || [],
        listaVuelta: props?.listaVuelta || [],
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.idRoute) {
        error.push("La línea es obligatoria")
    }

    if (schema.listaIda.length==0) {
        error.push("Recorrido de IDA es obligatorio")
    }

    if (schema.listaVuelta.length==0) {
        error.push("Recorrido de VUELTA es obligatorio")
    }

    return error
}
