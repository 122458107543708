import React, {useEffect, useState} from "react";
import {apiList, apiDelete} from "../../../../services/Admin/route";

import {Button, IconButton, Grid, Paper, Divider} from "@mui/material";
import MsgSuccess from "../../../../components/MsgSuccess";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Table from "../../../../components/Table";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import InputText from "../../../../components/Inputs/InputText";

import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalRemove from "../../../../components/Modal/ModalRemove";

import Icon from '@mui/material/Icon';
import CircleIcon from '@mui/icons-material/Circle';
import PreviewIcon from '@mui/icons-material/Preview';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


export default function Management() {
    const [rows, setRows] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [openModalRemove, setOpenModalRemove] = useState(false)
    const [isRemoveConfirm, setIsRemoveConfirm] = useState(false)
    const [idRemove, setIdRemove] = useState(null)

    const params = new URLSearchParams(window.location.search)

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
        if (state.sort.field === "active") { field = "isActive" }
       
        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.name) { params.name = state.name }
      
        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return {
                    active: doc.isActive ? "Si" : "No",
                    ...doc,
                }
            })

            setRows(temp) 
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    useEffect(() => {
        if (isRemoveConfirm) {
            
            apiDelete(idRemove).then(resp => {
                setMsgSuccess(resp.data?.msg)
                setIsRemoveConfirm(false)
                setIdRemove(null)
                getItemsData()
            }).catch(err => {
                setMsgError(err.response?.data?.msg)
                setIsRemoveConfirm(false)
                setIdRemove(null)
               // getItemsData()           
            })   
        }
    }, [isRemoveConfirm])

    const removeRow = (id) => {
        setIdRemove(id)
        setOpenModalRemove(true)
    }

    return (
        <>
            <ModalRemove
                open={openModalRemove}
                setOpen={setOpenModalRemove}
                setIsRemoveConfirm={setIsRemoveConfirm}
            />
            <HeaderPage title="Lineas">
                <Button
                    variant="contained"
                    color="primary"
                    style={{float: "right", margin: "20px 0px"}}
                    href={RouteAdmin.ROUTE_CREATE.path}
                >
                    + Añadir
                </Button>
            </HeaderPage>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgSuccess msg={msgSuccess}/>
                    <MsgError msg={msgError}/>
                </Grid>

                <Grid item xs={12}>

                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>

                        <Filters
                            state={state}
                            setState={setState}
                        />

                        <Table
                            columns={tableColumns(removeRow)}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "name", sort: "asc" },
        name:"",
    }
}

function tableColumns(removeRow) {
    return [
        {
            field: "id",
            headerName: "ID",
            flex: 1
        },
        {
            field: "color",
            headerName: "COLOR",
            flex: 1,
            renderCell: (params) => {
                if(!params.row.color) {return ""}
                return (
                    <Icon>
                        <CircleIcon style={{ color: params.row.color }}  fontSize="inherit"/>
                    </Icon>
                    )
            },
        },
        {
            field: "code",
            headerName: "CÓDIGO",
            flex: 1
        },
        {
            field: "name",
            headerName: "NOMBRE",
            flex: 3,

        },
        {
            field: "pdf",
            headerName: "PDF",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                    {params.row.pdf && (
                    <a href={params.row.pdf} target="_blank">
                            <IconButton color="primary" aria-label="edit linea" size="small">
                                <PreviewIcon fontSize="inherit" />
                            </IconButton>
                        </a>
                    )}
                    </>
                    )
            },
        },
        {
            field: "active",
            headerName: "ACTIVO",
            flex: 1
        },
        {
            field: "actions",
            headerName: "ACCIONES",
            flex: 1,
            renderCell: (params) => {
                return (<><Link to={`${RouteAdmin.ROUTE_EDIT.path}/${params.row.id}`}>
                            <IconButton color="primary" aria-label="edit linea" size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>

                          <IconButton color="primary" aria-label="delete linea" size="small" onClick={() => removeRow(params.row.id)}>
                            <DeleteIcon fontSize="inherit"/>
                        </IconButton></>)
            },
        },
    ]
}

function Filters(props) {

    const {state, setState} = props
    const {t} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
            <Grid item xs={6}>
                <InputText
                    label="Nombre"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                />
            </Grid>
             <Grid item xs={6}>
                <Button onClick={() => setState(initialValues())}>
                <HighlightOffIcon/>
                </Button>
            </Grid>
        </Grid>

    )
}
