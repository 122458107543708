import React from "react";
import {TextField} from "@mui/material";

/**
 * Muestra un input de texto
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **onChange** *function*
 * - **required** *bool*
 * - **rows** *integer*
 */
export default function InputText(props) {

    const {label, name, value, onChange, required, rows} = props

    const rowsIn = rows ?? 1
    const multilineIn = rows > 1 ? true : false

    return (
        <TextField
            InputLabelProps={{shrink: true}}
            variant="outlined"
            size="small"
            multiline={multilineIn}
            rows={rowsIn}
            fullWidth
            required={required}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
        />
    )
}
