import {createTheme, styled, ThemeProvider} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {Box, Container, Grid, IconButton, Toolbar, Typography} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import MenuAcount from "../../../../../components/Layouts/Admin/MenuAccount";
import * as React from "react";

const mdTheme = createTheme();

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function TopBar(props) {
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                            background: '#fff'
                        }}
                    >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="default"
                            noWrap
                            sx={{flexGrow: 1, color: '#000'}}
                        >
                            Dashboard
                        </Typography>

                        <MenuAcount/>
                    </Toolbar>
                </AppBar>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                        <Grid container spacing={3}>
                            {props.children}
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    )
}
