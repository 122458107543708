import React, {useState, useEffect} from "react";
import DndElements from "../../../components/DndElements";
import EditorText from "../../../components/EditorText";

export default function Testing() {
    const [text, setText] = useState(textInit)
    const [elements, setElementDnd] = useState(elementsInit)
    const [elementsV, setElementDndV] = useState(elementsInitV)

    return (
        <div style={{padding: "20px"}}>
            <h1>TESTING</h1>

            <h2>DnD - Drag and Drop</h2>
            <DndElements
                elementDnd={elements}
                setElementDnd={setElementDnd}
            />

            <DndElements
                direction="vertical"
                elementDnd={elementsV}
                setElementDnd={setElementDndV}
            />

            <h2>Editor</h2>
            <EditorText
                content={text}
                setContent={setText}
            />
        </div>
    )
}

const textInit = `<p>Esto es el texto del editor</p>
<img src="https://source.unsplash.com/8xznAGy4HcY/800x400" />
`

const elementsInit = [
    {
        id: "1",
        content: <img src="https://picsum.photos/id/237/200/300" style={{height: "100%", width: "200px"}} />
    },
    {
        id: "2",
        content: <img src="https://picsum.photos/seed/picsum/200/300" style={{height: "100%", width: "200px"}} />
    },
    {
        id: "3",
        content: <img src="https://picsum.photos/200/300?grayscale" style={{height: "100%", width: "200px"}} />
    }
]

const elementsInitV = [
    {
        id: "1",
        content: "A"
    },
    {
        id: "2",
        content: "B"
    },
    {
        id: "3",
        content: "C"
    }
]
