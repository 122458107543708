import React, {useEffect, useState} from "react";
import {apiList, apiDelete} from "../../../../services/Admin/trip";

import {Button, IconButton, Grid, Paper, Divider} from "@mui/material";
import MsgSuccess from "../../../../components/MsgSuccess";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Table from "../../../../components/Table";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import InputSelect from "../../../../components/Inputs/InputSelect";

import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalRemove from "../../../../components/Modal/ModalRemove";

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import {apiList as apiListRoute} from "../../../../services/Admin/route";


export default function Management() {
    const [rows, setRows] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [openModalRemove, setOpenModalRemove] = useState(false)
    const [isRemoveConfirm, setIsRemoveConfirm] = useState(false)
    const [idRemove, setIdRemove] = useState(null)
    const params = new URLSearchParams(window.location.search)

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])
    
    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
        if (state.sort.field === "active") { field = "isActive" }
        if (state.sort.field === "fsentido") { field = "sentido" }
       
        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.idTrip) { params.idTrip = state.idTrip }
        if (state.strip) { params.strip = state.strip }
      
        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return {
                    active: doc.isActive ? "Si" : "No",
                    fsentido: doc.sentido ? "VUELTA" : "IDA", // IDA -> 0 - VUELTA -> 1
                    ...doc,
                }
            })

            setRows(temp) 
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    useEffect(() => {
        if (isRemoveConfirm) {
            
            apiDelete(idRemove).then(resp => {
                setMsgSuccess(resp.data?.msg)
                setIsRemoveConfirm(false)
                setIdRemove(null)
                getItemsData()
            }).catch(err => {
                setMsgError(err.response?.data?.msg)
                setIsRemoveConfirm(false)
                setIdRemove(null)
                //getItemsData()           
            })   
        }
    }, [isRemoveConfirm])

    const removeRow = (id) => {
        setIdRemove(id)
        setOpenModalRemove(true)
    }

    return (
        <>
            <ModalRemove
                open={openModalRemove}
                setOpen={setOpenModalRemove}
                setIsRemoveConfirm={setIsRemoveConfirm}
            />
            <HeaderPage title="Rutas">
                <Button
                    variant="contained"
                    color="primary"
                    style={{float: "right", margin: "20px 0px"}}
                    href={RouteAdmin.TRIP_CREATE.path}
                >
                    + Añadir
                </Button>
            </HeaderPage>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgSuccess msg={msgSuccess}/>
                    <MsgError msg={msgError}/>
                </Grid>

                <Grid item xs={12}>

                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>

                        <Filters
                            state={state}
                            setState={setState}
                        />

                        <Table
                            columns={tableColumns(removeRow)}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "idTrip", sort: "asc" },
        name:"",
        idTrip:"",
        strip:"",
    }
}

function tableColumns(removeRow) {
    return [
        {
            field: "idTrip",
            headerName: "ID",
            flex: 1
        },
        {
            field: "routeName",
            headerName: "LINEA",
            flex: 1
        },
        {
            field: "fsentido",
            headerName: "TRAYECTO",
            flex: 1
        },
        {
            field: "stopName",
            headerName: "PARADA",
            flex: 1
        },
        {
            field: "orden",
            headerName: "ORDEN",
            flex: 1
        },
       
        // {
        //     field: "active",
        //     headerName: "ACTIVO",
        //     flex: 1
        // },
        {
            field: "actions",
            headerName: "ACCIONES",
            flex: 1,
            renderCell: (params) => {
                return (<>
                         {(!params.row.blockEdit && 
                                (
                        <Link to={`${RouteAdmin.TRIP_EDIT.path}/${params.row.idTrip}`}>
                            <IconButton color="primary" aria-label="edit ruta" size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                                ))}
                          <IconButton color="primary" aria-label="delete ruta" size="small" onClick={() => removeRow(params.row.idTrip)}>
                            <DeleteIcon fontSize="inherit"/>
                        </IconButton></>)
            },
        },
    ]
}

function Filters(props) {

    const {state, setState} = props
    const {t} = useTranslation()
    const [route, setRoute] = useState([])

    const optionsSentido = [{id: "IDA", name: "IDA"}, {id: "VUELTA", name: "VUELTA"}]

    useEffect(() => {void getRoute()}, [])

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const getRoute = () => {

        apiListRoute({
            field: "name",
            sort: "asc"
        }).then(resp => {
            setRoute(resp.data?.data)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
            <Grid item xs={6}>
            <InputSelect
                label="Línea"
                name="idTrip"
                onChange={handleChange}
                value={state?.idTrip}
                options={route || []}
            />
            </Grid>
            <Grid item xs={3}>
            <InputSelect
                label="Trayecto"
                name="strip"
                onChange={handleChange}
                value={state?.strip}
                options={optionsSentido || []}
            />
            </Grid>
             <Grid item xs={3}>
                <Button onClick={() => setState(initialValues())}>
                <HighlightOffIcon/>
                </Button>
            </Grid>
        </Grid>

    )
}
