import React from "react";
import {Divider, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {People} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import {RouteAdmin} from "../../../routes/RouteAdmin";
import {Link} from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import RouteIcon from '@mui/icons-material/Route';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AltRouteIcon from '@mui/icons-material/AltRoute';

export default function menuPrimary(props) {
    const {menu} = props
    const {t} = useTranslation()

    return (
        <div style={{marginTop: 20}}>
            <MenuLink type="incidents" menu={menu} href={RouteAdmin.INCIDENTS_MANAGEMENT.path} name="Incidencias" icon={<FmdBadIcon />} />

            <Divider style={{marginBottom: 10, marginTop: 10}}/>

            <MenuLink type="route" menu={menu} href={RouteAdmin.ROUTE_MANAGEMENT.path} name="Líneas" icon={<AltRouteIcon />}/>
            <MenuLink type="stop" menu={menu} href={RouteAdmin.STOP_MANAGEMENT.path} name="Paradas" icon={<DirectionsBusFilledIcon />}/>
            <MenuLink type="trip" menu={menu} href={RouteAdmin.TRIP_MANAGEMENT.path} name="Rutas" icon={<RouteIcon />}/>
            <MenuLink type="schedule" menu={menu} href={RouteAdmin.SCHEDULE_MANAGEMENT.path} name="Horarios" icon={<HistoryToggleOffIcon />}/>
            <MenuLink type="holiday" menu={menu} href={RouteAdmin.HOLIDAY_MANAGEMENT.path} name="Festivos" icon={<CalendarTodayIcon />} />

            <Divider style={{marginBottom: 10, marginTop: 10}}/>

            <MenuLink type="rates" menu={menu} href={RouteAdmin.RATES_MANAGEMENT.path} name="Tarifas" icon={<EuroSymbolIcon />}/>
            <MenuLink type="city" menu={menu} href={RouteAdmin.CITY_MANAGEMENT.path} name="Poblaciones" icon={<LocationCityIcon />}/>

            <Divider style={{marginBottom: 10, marginTop: 10}}/>

            <MenuLink type="users" menu={menu} href={RouteAdmin.USER_MANAGEMENT.path} name={t('user.users')} icon={<People />}/>

            {/*<MenuLink type="media" menu={menu} href={RouteAdmin.MEDIA_MANAGEMENT.path} name="Media" icon={<PermMediaIcon />} endIcon={<ErrorIcon style={{color: "#fad744"}}/>}/>*/}
        </div>
    )
}

function MenuLink(props) {
    const {href, name, menu, type, icon, endIcon} = props

    let isActive = false
    if (menu === type) {
        isActive = true
    }

    return (
        <>
            <div  style={{ marginRight: '25px' }}>
                <ListItem
                    component={Link}
                    to={href}
                    selected={isActive}
                    sx={{ borderRadius: '8px', marginLeft: '10px', color: '#000' }}
                >
                    {endIcon && (
                        <ListItemIcon>
                            {endIcon}
                        </ListItemIcon>
                    )}

                    {!endIcon && (
                        <ListItemIcon>
                            {icon}
                        </ListItemIcon>
                    )}
                    <ListItemText primary={name} />

                </ListItem>
            </div>
        </>
    )
}
