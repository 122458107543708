import React from 'react';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import {TextField} from "@mui/material";
import moment from "moment";
import {MobileDateTimePicker} from "@mui/lab";

const localeMap = {
    en: enLocale,
    es: esLocale,
}

const cancelText = {
    en: "Cancel",
    es: "Cancelar",
}

const okText = {
    en: "Ok",
    es: "Aceptar",
}

/**
 * Muestra un input de de fecha
 *
 * ## PROPS
 * - **value** *timestamp*
 * - **name** *string*
 * - **label** *string*
 * - **onChange** *function*
 * - **locale** *string*
 * - **hour** *boolean*
 */
export default function DatePickerSimple(props) {

    const {value, name, label, onChange, locale, hour} = props
    const valueIn = value ? moment.unix(value).format("YYYY-M-DD HH:mm") : null

    const localeIn = locale || "es"
    const hourIn = hour || false

    const handleChange = (newValue) => {
        onChange({target: {name: name, value: moment(newValue).format("X")}})
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[localeIn]}>
            <Stack spacing={3}>
                {hourIn && (
                    <MobileDateTimePicker
                        cancelText={cancelText[localeIn]}
                        okText={okText[localeIn]}
                        label={label}
                        value={valueIn}
                        onChange={handleChange}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField ref={inputRef} {...inputProps} size="small"  fullWidth placeholder={label} />
                            </Box>
                        )}
                    />
                )}

                {!hourIn && (
                    <MobileDatePicker
                        cancelText={cancelText[localeIn]}
                        okText={okText[localeIn]}
                        label={label}
                        value={valueIn}
                        onChange={handleChange}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField ref={inputRef} {...inputProps} size="small" fullWidth placeholder={label} />
                            </Box>
                        )}
                    />
                )}
            </Stack>
        </LocalizationProvider>
    );
}
