import Homepage from "../pages/User/Homepage"

const baseTitle = "BusGarraf - "

export const RouteUser = {
    ROOT: {
        path: "/",
        page: Homepage,
        title: `${baseTitle}Homepage`,
        roles: ["ROLE_USER"]
    }
}
