import React, {useEffect, useState} from "react";
import {apiList, apiDelete} from "../../../../services/Admin/incidents";
import moment from "moment";
import {Button, Grid, IconButton, Input, keyframes, Paper} from "@mui/material";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Table from "../../../../components/Table";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import InputText from "../../../../components/Inputs/InputText";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import DatePickerSimple from "../../../../components/Inputs/DatePickerSimple";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalRemove from "../../../../components/Modal/ModalRemove";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MsgSuccess from "../../../../components/MsgSuccess";
import LoadingBar from "../../../../components/LoadingBar";

export default function Management() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [openModalRemove, setOpenModalRemove] = useState(false)
    const [isRemoveConfirm, setIsRemoveConfirm] = useState(false)
    const [idRemove, setIdRemove] = useState(null)

    const params = new URLSearchParams(window.location.search)

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    const getItemsData = () => {
        setMsgError([])
        setMsgSuccess([])
        setLoading(true)

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: state.sort.field,
            sort: state.sort.sort
        }

        if (state.route) { params.route = state.route }
        if (state.stop) { params.stop = state.stop }
        if (state.dateFrom) { params.dateFrom = state.dateFrom }
        if (state.dateTo) { params.dateTo = state.dateTo }
        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map((doc,key)=> {
                
                return {
                    ...doc,
                    idIncident: doc.id,
                    id:key,
                    dateFrom: moment.unix(doc.dateFrom).format("D/M/Y HH:mm"),
                    dateTo: moment.unix(doc.dateTo).format("D/M/Y HH:mm")
                }
            })
          
            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    useEffect(() => {
        if (isRemoveConfirm) {
            setLoading(true)
            setMsgError([])
            setMsgSuccess([])
            apiDelete(idRemove).then(resp => {
                setMsgSuccess(resp.data?.msg)
                setIsRemoveConfirm(false)
                setIdRemove(null)
                getItemsData()
            }).catch(err => {
                setMsgError(err.response?.data?.msg)
                setIsRemoveConfirm(false)
                setIdRemove(null)
                getItemsData()
            })
        }
    }, [isRemoveConfirm])

    const removeRow = (id) => {
        setIdRemove(id)
        setOpenModalRemove(true)
    }

    return (
        <>
            <ModalRemove
                open={openModalRemove}
                setOpen={setOpenModalRemove}
                setIsRemoveConfirm={setIsRemoveConfirm}
            />
            <HeaderPage title="Incidencias">
                <Button
                    variant="contained"
                    color="primary"
                    style={{float: "right", margin: "20px 0px"}}
                    href={RouteAdmin.INCIDENTS_CREATE.path}
                >
                    + Añadir
                </Button>
            </HeaderPage>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgError msg={msgError}/>
                    <MsgSuccess msg={msgSuccess}/>
                </Grid>

                <Grid item xs={12}>

                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>

                        <Grid item xs={12} sx={{p:2}}>
                            <Filters
                                state={state}
                                setState={setState}
                            />

                            <Table
                                columns={tableColumns(removeRow)}
                                rows={rows}
                                rowCount={rowCount}
                                filter={state}
                                setFilter={setState}
                                loading={loading}
                                checkbox={true}
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        route: null,
        stop: null,
        dateFrom: null,
        dateTo: null
    }
}

function tableColumns(removeRow) {
    return [
        // {
        //     field: "id",
        //     headerName: "ID",
        //     flex: 1
        // },
        {
            field: "routeName",
            headerName: "LÍNEA",
            flex: 1
        },
        {
            field: "stopName",
            headerName: "PARADA",
            flex: 1
        },
        {
            field: "dateFrom",
            headerName: "FECHA DESDE",
            flex: 1
        },
        {
            field: "dateTo",
            headerName: "FECHA HASTA",
            flex: 1
        },
        {
            field: "actions",
            headerName: "ACCIONES",
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`${RouteAdmin.INCIDENTS_EDIT.path}/${params.row.idIncident}`}>
                            <IconButton color="primary" aria-label="upload picture" size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                        <IconButton color="primary" aria-label="upload picture" size="small" onClick={() => removeRow(params.row.idIncident)} >
                            <DeleteIcon fontSize="inherit"/>
                        </IconButton>
                    </>
                )
            },
        },
    ]
}

function Filters(props) {

    const {state, setState} = props
    const {t} = useTranslation()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
            <Grid item xs={6}>
                <InputText
                    label="Línea"
                    name="route"
                    value={state.email}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6}>
                <InputText
                    label="Parada"
                    name="stop"
                    value={state.email}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6}>
                <DatePickerSimple
                    label="Fecha desde"
                    name="dateFrom"
                    value={state.dateFrom}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={5}>
                <DatePickerSimple
                    label="Fecha hasta"
                    name="dateTo"
                    value={state.dateTo}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={1}>
                <Button fullWidth onClick={() => setState(initialValues())}>
                    <HighlightOffIcon />
                </Button>
            </Grid>
        </Grid>
    )
}
