import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";

import {apiCreate, apiUpdate, apiGetRate, apiGetRatesStops} from "../../../../services/Admin/rate";
import InputSelect from "../../../../components/Inputs/InputSelect";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Grid, Paper, Box, Tab} from "@mui/material";
import { TabPanel, TabContext, TabList } from '@mui/lab';
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import LoadingBar from "../../../../components/LoadingBar";
import TableEditRate from "../../../../components/TableEditRate";
import {apiList as apiListRoute} from "../../../../services/Admin/route";
import {apiList as apiListTrip} from "../../../../services/Admin/trip";
import moment from "moment";
import { RedoRounded } from "@mui/icons-material";


export default function Edit() {
    const {id} = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const params = new URLSearchParams(window.location.search)

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)

    const [rowsIda, setRowsIda] = useState([])
    const [rowsVuelta, setRowsVuelta] = useState([])
    const [colParadasIda, setColParadasIda] = useState([])
    const [colParadasVuelta, setColParadasVuelta] = useState([])

    const myRef = useRef(null)

    const [route, setRoute] = useState([])

    const [valueTabList, setValueTabList] = useState('1');

    const [isSaving, setIsSaving ] = useState(false);

    const [canGetRates, setCanGetRates] = useState(false)

    useEffect(() => {
        if (params.get("msg-error")) {
            setMsgError([params.get("msg-error")])
        } else if (params.get("msg")) {
            setMsgSuccess([params.get("msg")])
        }
    }, [])

    // Llamamos a la API para obtener los datos de la parada
    useEffect(() => {
        void getRoute()
        if (id) {
            void getRate()
         
        } else {
            setLoadingGetInfo(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps



    // useEffect(() => {
    //    if(!loading && id){
    //         const timeOutId = setTimeout(() => {
    //             handleSubmit(false)
    //         }, 20000)
    //         return () => clearTimeout(timeOutId)
    //     }
    // }, [rowsIda, rowsVuelta])

    const getRoute = () => {

        let params;
        if(id){
                params={
                //Lineas sin tarifa
                field: "name",
                sort: "asc",
            }
        }else
        {
            params={
                //Lineas sin tarifa
                field: "name",
                sort: "asc",
                rates: "no",
            }
        }
        

        apiListRoute(params).then(resp => {
            setRoute(resp.data?.data)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }

    useEffect(() => {
        if (state.idRoute) {
            // Por si cambia de linea al crear y habia introducido horarios -> reset de horario
            setRowsIda([])
            setRowsVuelta([])
            void getTrip()
        }
    }, [state.idRoute]) // eslint-disable-line react-hooks/exhaustive-deps

    const getTrip = () =>{
        apiListTrip({
            field: "idTrip",
            sort: "asc",
            idTrip:state.idRoute
        }).then(resp => {
           
            setCanGetRates(false)
                    
           // Creamos cols IDA - VUELTA
            // { field: 'name1', headerName: 'Vilanova-sitges', width: 180, editable: true },
            // Creamos primera col de cada fila IDA - VUELTA

            let filaIda = []
            let filaVuelta = []
            let colIda = []
            let colVuelta = []
            let i = 1
            let j = 1
            
            state.idTrip= resp.data?.data[0].idTrip


            resp.data?.data.forEach((element, index) => {
              
            if(element.sentido == 0) {
                if(i==1)
                {
                  filaIda.push({
                      "field":"name",
                      "headerName": "Stops",
                      "editable": false,
                      "disableColumnMenu": true,
                      "disableColumnReorder": true,
                      "sortable": false,
                      "flex": 1,
                      "cellClassName": 'txt-bold',
                      "headerAlign": 'left',
                  })  
                }
                filaIda.push({
                    "field":"name"+i,
                    "headerName": element.stopName,
                    "editable": true,
                    "disableColumnMenu": true,
                    "disableColumnReorder": true,
                    "sortable": false,
                    "flex": 1,
                     "headerAlign": 'center',
                     "cellClassName": 'txt-center',
                })
                
                colIda.push({id:i, name:element.stopName})   
                for(let k=1;k<=i;k++)
                {
                  let name="name"+k.toString();
                  colIda[i-1]={...colIda[i-1],[name]:"X"}
                }

                i++;
            } else {
                if(j==1)
                {
                  filaVuelta.push({
                    "field":"name",
                    "headerName": "Stops",
                    "editable": false,
                    "disableColumnMenu": true,
                    "disableColumnReorder": true,
                    "sortable": false,
                    "flex": 1,
                    "headerAlign": 'left',
                    "cellClassName": 'txt-bold',
                  })  
                }
                filaVuelta.push({
                  "field":"name"+j,
                  "headerName": element.stopName,
                  "editable": true,
                  "disableColumnMenu": true,
                  "disableColumnReorder": true,
                  "sortable": false,
                  "flex": 1,
                  "headerAlign": 'center',
                  "cellClassName": 'txt-center',
                })
                colVuelta.push({id:j, name:element.stopName})
                for(let k=1;k<=j;k++)
                {
                  let name="name"+k.toString();
                  colVuelta[j-1]={...colVuelta[j-1],[name]:"X"}
                }
                j++;
            }
          })
         // console.log("filas")
          setColParadasIda(filaIda)
          setColParadasVuelta(filaVuelta)

          setRowsIda(colIda)
          setRowsVuelta(colVuelta)
          
          setCanGetRates(true)
            

        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }

   
    const getRate = () => {
        setMsgError([])

        apiGetRate(id).then(resp => {

            setState(initialValues(resp.data?.data))
            setLoadingGetInfo(false)
           
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response?.data?.msg)
        })
    }

    useEffect(() => {
        
        if (canGetRates) {
            //console.log("ue")
            getRatesStops();
        }
    }, [canGetRates]) // eslint-disable-line react-hooks/exhaustive-deps

    const getRatesStops = () => {

        if(id)
        {   //console.log("gRs")
            setMsgError([])

            apiGetRatesStops(id).then(resp => {

                setLoadingGetInfo(false)
                
                let tempIda=[];
                let tempVuelta=[];

                rowsIda.forEach((element, index) => 
                {
                    tempIda.push(element)      
                });

                rowsVuelta.forEach((element, index) => 
                {
                    tempVuelta.push(element)
                });
                
                
                
                resp.data?.data.forEach((element, index) => {
                    
                
                    if(element.sentido == 0) {
                        if(element.price != null)
                        {  
                            let name="name"+element.col.toString();
                            let col=element.pos-1;
                            tempIda[col]={...tempIda[col],[name]:element.price}
                        }else
                        {
                            let name="name"+element.col.toString();
                            let col=element.pos-1;
                            tempIda[col]={...tempIda[col],[name]:"X"}
                        }
                    }
                    else
                    {
                        if(element.price != null)
                        {  
                            let name="name"+element.col.toString();
                            let col=element.pos-1;
                            tempVuelta[col]={...tempVuelta[col],[name]:element.price}
                        }else
                        {
                            let name="name"+element.col.toString();
                            let col=element.pos-1;
                            tempVuelta[col]={...tempVuelta[col],[name]:"X"}
                        }
                    }


                })
                
                setRowsIda(tempIda)
                setRowsVuelta(tempVuelta)


            }).catch(err => {
                setLoadingGetInfo(false)
                setMsgError(err.response?.data?.msg)
            })
        }
    }


    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        //console.log(state)
        setState({...state})
    }

    
    const handleSubmit = (scrollTop = true) => {
        // event.preventDefault()

        setLoading(true)
        setMsgSuccess([])
        setMsgError([]) 
        setIsSaving(true)

        state.ratesIda=rowsIda;
        state.ratesVuelta=rowsVuelta;
      
        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            setIsSaving(false)
            myRef.current.scrollIntoView()
            return
        }

        // Validar que no hay filas vacias

        let numColsIda=colParadasIda.length;
        let numColsVuelta=colParadasVuelta.length;
        let hasValue = false;
        let errorFilaIda =false;
        let errorFilaVuelta =false;

        rowsIda.forEach((element, index) => {
            hasValue = false;
            for(let i=1;i<=numColsIda;i++)
            {
                let key = "name"+i;
                if(key in element)
                {
                    if(element[key]!="")
                    {
                        hasValue=true;
                        break;
                    }
                }
            }
            if(!hasValue)
            {
                errorFilaIda=true;
            }
        });

        rowsVuelta.forEach((element, index) => {
            hasValue = false;
            for(let i=1;i<=numColsVuelta;i++)
            {
                let key = "name"+i;
                if(key in element)
                {
                    if(element[key]!="")
                    {
                        hasValue=true;
                        break;
                    }
                }
            }
            if(!hasValue)
            {
                errorFilaVuelta=true;
            }
        });

        if (errorFilaIda && errorFilaVuelta) {
            setMsgError(["Hay una o más filas vacias en IDA y VUELTA"])
            setLoading(false)
            setIsSaving(false)
             if(scrollTop){ myRef.current.scrollIntoView()}
            return
        }

        if (errorFilaIda) {
            setMsgError(["Hay una o más filas vacias en IDA"])
            setLoading(false)
            setIsSaving(false)
            if(scrollTop){ myRef.current.scrollIntoView()}
            return
        }

        if (errorFilaVuelta) {
            setMsgError(["Hay una o más filas vacias en VUELTA"])
            setLoading(false)
            setIsSaving(false)
            if(scrollTop){ myRef.current.scrollIntoView()}
            return
        }

        if (id) {
            void updateSchedule(scrollTop)
        } else {
            void createSchedule()
        }
    }

    const updateSchedule = (scrollTop) => {
        apiUpdate(state).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            setIsSaving(false)
           if(scrollTop){ myRef.current.scrollIntoView()}
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setIsSaving(false)
           if(scrollTop){ myRef.current.scrollIntoView()}
        })
    }

    const createSchedule = () => {
        apiCreate(state).then(resp => { 
            setIsSaving(false)
            history.push(`${RouteAdmin.RATES_EDIT.path}/${resp.data?.id}?msg=${resp.data?.msg}`)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setIsSaving(false)
            myRef.current.scrollIntoView()
        })
    }

    const handleChangeTabList = (event, newValue) => {
        
        setValueTabList(newValue);
    }

    return (
        <>
            <div ref={myRef}></div>
            <HeaderPage title="Configurar Tarifa" urlBack={RouteAdmin.RATES_MANAGEMENT.path}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <LoadingBar loading={loadingGetInfo} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">{t('user.settings')}</h4>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12}>
                                        <InputSelect
                                            label="Linea"
                                            name="idRoute"
                                            required
                                            onChange={handleChange}
                                            value={state?.idRoute}
                                            options={route}
                                            disabled={state?.idRoute !== "" && id!=undefined}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                            
                            <Grid item xs={12} md={12} lg={12}>
                            {(state.idRoute!="" && 
                                (
                                <>
                                <Box sx={{ width: '100%' }} >
                                <TabContext value={valueTabList}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList aria-label="Horarios IDA - VUELTA" onChange={handleChangeTabList}>
                                            <Tab label="IDA" value="1"/>
                                            <Tab label="VUELTA"  value="2"/>
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1" index={0} style={{ flexGrow: 1 }}> 
                                        <TableEditRate
                                                    columns={colParadasIda}
                                                    rows={rowsIda}
                                                    setRows={setRowsIda}
                                                    isSaving={isSaving}
                                                    /></TabPanel>
                                    <TabPanel value="2" index={1} style={{ flexGrow: 1 }}> 
                                        <TableEditRate
                                                    columns={colParadasVuelta}
                                                    rows={rowsVuelta}
                                                    setRows={setRowsVuelta}
                                                    isSaving={isSaving}
                                                    />
                                    </TabPanel>
                                </TabContext>
                                </Box>
                                </>
                                )
                            )}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? 'Modificar' : 'Crear'}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
   
    return {
        id: props?.id || "",
        idRoute: props?.idRoute || "",
        idTrip: props?.idTrip || "",
        rowsIda: props?.rowsIda || [],
        rowsVuelta:props?.rowsVuelta || [],   
    } 
}


function validationSchema(schema) {

    let error = []

    if (!schema.idRoute) {
        error.push("La linea es obligatoria")
    }
    

    return error
}
